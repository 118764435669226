import { format } from 'date-fns';
import { CoronaData } from '@/lib/corona/index';
import { LegalForm } from '@/lib/corona/costs';

export const EXPLOITATION_FACTOR = 0.01;
export const SALES_COST_FACTOR = 0.05;
export const OFFICE_COST_FACTOR = 0.005;
export const REST_COST_FACTOR = 0.01;

export function getExploitationColumn(
  data: CoronaData,
  yearIndex: number,
  month: number,
  monthIndex: number,
  previousAnnuity: number[] = [],
): ExploitationColumn {
  const turnover = data.revenue[yearIndex][monthIndex];

  const salary = turnover * data.costs.avgPersonnelCostFactor;

  const stockCost = turnover * data.costs.avgPurchaseWorthFactor;

  const rent = data.costs.rentIncl
    ? data.costs.monthlyRent / 1.21
    : data.costs.monthlyRent;

  const exploitation = turnover * EXPLOITATION_FACTOR;
  const sales = turnover * SALES_COST_FACTOR;
  const officeCosts = turnover * OFFICE_COST_FACTOR;
  const rest = turnover * REST_COST_FACTOR;

  const interest =
    (data.costs.loanAmount +
      previousAnnuity.length * data.costs.loanAnnuity +
      previousAnnuity.reduce((acc, v) => acc + v, 0)) *
    (data.costs.loanInterest / 12);

  const actualYear = data.yearForIndex(yearIndex, monthIndex);
  const date = new Date(actualYear, month, 1);
  return new ExploitationColumn(
    date,
    data.costs.legalForm,
    turnover,
    stockCost,
    salary,
    rent,
    exploitation,
    sales,
    officeCosts,
    rest,
    interest,
    data.costs.monthlyWriteOff,
    data.costs.managementFee,
  );
}

export class ExploitationColumn {
  month: Date;
  legalForm: LegalForm;
  turnover: number;
  stockValue: number;
  salary: number;
  rent: number;
  exploitation: number;
  sales: number;
  officeCosts: number;
  restCosts: number;
  annuity: number;
  monthlyWriteOff: number;
  managementFee: number;

  constructor(
    month: Date,
    legalForm: LegalForm,
    turnover: number,
    stockValue: number,
    salary: number,
    rent: number,
    exploitation: number,
    sales: number,
    officeCosts: number,
    restCosts: number,
    annuity: number,
    monthlyWriteOff: number,
    managementFee: number,
  ) {
    this.month = month;
    this.legalForm = legalForm;
    this.turnover = turnover;
    this.stockValue = stockValue;
    this.salary = salary;
    this.rent = rent;
    this.exploitation = exploitation;
    this.sales = sales;
    this.officeCosts = officeCosts;
    this.restCosts = restCosts;
    this.annuity = annuity;
    this.monthlyWriteOff = monthlyWriteOff;
    this.managementFee = managementFee;
  }

  computeRevenueTax(vatFactor: number): number {
    return this.turnover * vatFactor - this.revenueTax;
  }

  get totalCosts(): number {
    let totalCosts =
      this.salary +
      this.rent +
      this.exploitation +
      this.sales +
      this.officeCosts +
      this.restCosts +
      this.annuity +
      this.monthlyWriteOff;

    if (this.legalForm === LegalForm.BV) {
      totalCosts += this.managementFee;
    }

    return totalCosts;
  }

  get revenueTax(): number {
    let totalCosts =
      this.rent +
      this.exploitation +
      this.sales +
      this.officeCosts +
      this.restCosts;

    if (this.legalForm === LegalForm.BV) {
      totalCosts += this.managementFee;
    }

    return 0.21 * totalCosts;
  }

  get margin(): number {
    return this.turnover - this.stockValue;
  }

  get grossRevenue(): number {
    return this.margin - this.totalCosts;
  }

  get humanMonth(): string {
    return format(this.month, 'MMMM');
  }
}
