import store from '@/store';

export interface ThemeColors {
  background: string;
  backgroundCard: string;

  primary: string;
  primaryDark: string;
  primaryTransparent: string;

  text: string;
  textPrimary: string;
  textPlaceholder: string;

  line: string;
}

export const THEME_LIGHT: ThemeColors = {
  background: '#fafafa',
  backgroundCard: '#ffffff',

  primary: '#04ba41',
  primaryDark: '#00880b',
  primaryTransparent: '#04ba4133',

  text: '#4a4a4a',
  textPrimary: '#ffffff',
  textPlaceholder: '#808080',

  line: '#eeeeee',
};

export const THEME_DARK: ThemeColors = {
  background: '#212121',
  backgroundCard: '#303030',

  primary: '#014f1b',
  primaryDark: '#013512',
  primaryTransparent: '#014f1b33',

  text: '#ffffff',
  textPrimary: '#ffffff',
  textPlaceholder: '#808080',

  line: '#282828',
};

const SWATCH_COLORS = [
  'background',
  'background-card',
  'primary',
  'primary-dark',
  'primary-transparent',
  'text',
  'text-primary',
  'text-placeholder',
  'line',
];

export function currentSwatch(): { [color: string]: string } {
  const style = getComputedStyle(document.documentElement);
  const map: { [color: string]: string } = {};

  for (const color of SWATCH_COLORS) {
    // The shortcircuit is because Chrome and Safari in their infinite wisdom
    // defy the fucking W3C spec and return null instead of an empty string.
    map[color] = (style.getPropertyValue('--color-' + color) || '').replace(
      /\s/g,
      '',
    );
  }

  return map;
}

export function cssColorToHex(color: string): string {
  if (!color) {
    return '';
  }

  // Simple way to exit: Its already hex!
  if (color[0] === '#') {
    return color;
  }

  // Check if its RGB!
  if (color.startsWith('rgb')) {
    const match = color.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i,
    );
    return match && match.length === 4
      ? '#' +
          ('0' + parseInt(match[1], 10).toString(16)).slice(-2) +
          ('0' + parseInt(match[2], 10).toString(16)).slice(-2) +
          ('0' + parseInt(match[3], 10).toString(16)).slice(-2)
      : '';
  }

  // We don't support the rest *yet* (hsv)
  return '';
}

export interface PreferencesThemeInfo {
  type: 'light' | 'dark' | 'custom';
  logo?: string;
  colors?: ThemeColors;
}

export async function applyThemeInfo(
  themeInfo: PreferencesThemeInfo,
): Promise<void> {
  if (!themeInfo.type) return;

  if (themeInfo.type === 'custom') {
    await store.dispatch('theme/set', themeInfo);
  } else if (themeInfo.type === 'dark') {
    await store.dispatch('theme/setColorScheme', THEME_DARK);
  }
}
