import { BaseService } from './base';
import { Invoice } from '@/models';

export interface QuotationInfoResponse {
  has_tac: boolean;
  quotation: Invoice;
  state: boolean | null;
  company: string;
}

class QuotationService extends BaseService {
  public async info(link: string): Promise<QuotationInfoResponse> {
    const { data } = await this.axios().get(`/external/quotation/${link}`);
    const response = data.data;
    response.quotation = Invoice.deserialize(response.quotation);

    return response as QuotationInfoResponse;
  }

  public async accept(link: string): Promise<void> {
    await this.axios().post(`/external/quotation/${link}/accept`);
  }

  public async reject(link: string): Promise<void> {
    await this.axios().post(`/external/quotation/${link}/reject`);
  }
}
export default new QuotationService();
