// Accurate as of 2019-08-16
// But hey this probably changes a few times so add as neccesary

export enum ApiErrorCode {
  Todo = -1,

  InternalServerError = 0,
  NoBodyReceived = 1,
  InvalidCredentials = 2,
  NotFound = 3,
  InvalidRequest = 4,
  ValidationFailed = 5,

  LoginCredentialsInvalid = 10,
  LoginCompanyInvalid = 11,
  LoginRequiresAdditional = 12,
  LoginTokenExpired = 13,

  VatCodeRequestFailed = 101,

  CustomerNotFound = 110,
  CustomerCreateFailed = 111,
  CustomerPersonMismatch = 112,

  ArchiveInvalidRequest = 200,
}
