import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';

export class Registration {
  @IsEmail()
  @autoserializeAs('email')
  public email: string;

  @IsNotEmpty()
  @autoserializeAs('name')
  public name: string;

  @IsNotEmpty()
  @autoserializeAs('company_name')
  public companyName: string;

  // Password, only for creation
  @IsNotEmpty()
  @MinLength(8)
  @autoserializeAs('password')
  public password: string;
  public passwordConfirm: string;

  @IsNotEmpty()
  @autoserializeAs('country')
  public country: string;

  @IsNotEmpty()
  @autoserializeAs('language')
  public language: string;

  @autoserializeAs('promocode')
  public promocode: string;

  @IsNotEmpty()
  @autoserializeAs('captcha')
  public captcha: string;

  static deserialize(json: Record<string, unknown>): Registration {
    return Deserialize(json, Registration);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Registration);
  }
}
