import { BaseService } from './base';
import { LedgerNumber } from '@/models';

class LedgerNumbersService extends BaseService {
  async all(): Promise<LedgerNumber[]> {
    const { data } = await this.axios().get(`/settings/ledgernumbers`);
    return data.data.map(LedgerNumber.deserialize);
  }

  async create(ln: LedgerNumber): Promise<LedgerNumber> {
    const { data } = await this.axios().post(
      `/settings/ledgernumbers`,
      ln.serialize(),
    );
    return LedgerNumber.deserialize(data.data);
  }

  async update(ln: LedgerNumber): Promise<LedgerNumber> {
    const { data } = await this.axios().put(
      `/settings/ledgernumbers/${ln.id}`,
      ln.serialize(),
    );
    return LedgerNumber.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/ledgernumbers/${id}`);
  }

  async getArba(): Promise<string> {
    const { data } = await this.axios().get(`/settings/ledgernumberdebit`);
    return data.data.ledgerNumber;
  }

  async setArba(arba: string): Promise<string> {
    const { data } = await this.axios().put(`/settings/ledgernumberdebit`, {
      ledgerNumber: arba,
    });
    return data.data.ledgerNumber;
  }
}

export default new LedgerNumbersService();
