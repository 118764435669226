import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class Notification {
  @autoserializeAs('id') public id: string;
  @autoserializeAs('message') public message: string;
  @autoserializeAs('data') public data: any;
  @autoserializeAs('time') public time: string;

  static deserialize(json: Record<string, unknown>): Notification {
    return Deserialize(json, Notification);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Notification);
  }
}
