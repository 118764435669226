import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { InvoiceLedger } from '@/models/invoice';

export class LedgerNumber {
  @autoserializeAs('id') id: number;
  @autoserializeAs('name') name: string;
  @autoserializeAs('ledgernumber') ledgerNumber: string | null;
  @autoserializeAs('costcenter') costCenter: string | null;
  @autoserializeAs('book_taxless') bookTaxless: boolean;

  toInvoiceLedger(): InvoiceLedger {
    const ledger = new InvoiceLedger();

    ledger.id = this.id;
    ledger.name = this.name;
    ledger.code = this.ledgerNumber || '';
    ledger.credit = this.costCenter || '';

    return ledger;
  }

  static deserialize(json: Record<string, unknown>): LedgerNumber {
    return Deserialize(json, LedgerNumber);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, LedgerNumber);
  }
}
