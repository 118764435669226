import { MutationTree } from 'vuex';
import { AuthState, TokenData } from './state';

function setToken(state: AuthState, token: string): void {
  state.token = token;
}

function setData(state: AuthState, parsedToken: TokenData): void {
  Object.assign(state.data, parsedToken);
}

function clear(state: AuthState): void {
  Object.assign(state, new AuthState());
}

export default {
  setToken,
  setData,
  clear,
} as MutationTree<AuthState>;
