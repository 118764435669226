import { BaseService } from './base';
import { EmailAddress, EmailAddressType } from '@/models';
import { currentAddress } from '@/lib/helpers';

class EmailAddressService extends BaseService {
  async all(type: EmailAddressType | 'all' = 'all'): Promise<EmailAddress[]> {
    const { data } = await this.axios().get(`/settings/email/address`);
    const emails = data.data.map(EmailAddress.deserialize);

    if (type === 'all') {
      return emails;
    }

    return emails.filter((e) => e.type === type);
  }

  async create(email: EmailAddress): Promise<EmailAddress> {
    const { data } = await this.axios().post(`/settings/email/address`, {
      ...email.serialize(),
      redirect: `${currentAddress()}/verify`,
    });
    return EmailAddress.deserialize(data.data);
  }

  async delete(id: number, type: EmailAddressType): Promise<void> {
    await this.axios().delete(`/settings/email/address/${id}/${type}`);
  }

  async verify(data: Record<string, unknown>): Promise<void> {
    await this.axios().post(`/settings/email/verify`, data);
  }
}

export default new EmailAddressService();
