import { BaseService } from './base';
import { Notification } from '@/models';

class NotificationsService extends BaseService {
  async all(): Promise<Notification[]> {
    const { data } = await this.axios().get(`/notifications`);
    return data.data.map(Notification.deserialize);
  }

  async dismiss(id: string): Promise<void> {
    void id;
    throw new Error('No endpoint available');
  }

  async dismissAll(): Promise<void> {
    await this.axios().delete(`/notifications`);
  }
}

export default new NotificationsService();
