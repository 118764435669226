import Vue from 'vue';
import Vuex from 'vuex';
import { Auth, Settings, Theme } from './modules';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  modules: {
    auth: new Auth(),
    settings: new Settings(),
    theme: new Theme(),
  },
  strict: debug,
});
