/**
 * Look, google-libphonenumber is HUGE
 * Like, so absolutely massive, that it is about the size of all our other dependencies TOGETHER
 * So we shim it out with a webpack resolve alias
 *
 * @type {{PhoneNumberUtil: {getInstance(): null}}}
 */
module.exports = {
  PhoneNumberUtil: {
    getInstance() {
      return null;
    },
  },
};
