import { BaseService } from './base';

class InvoiceCommentService extends BaseService {
  async create(invoiceId: number, message: string): Promise<void> {
    await this.axios().post(`/invoice/${invoiceId}/comment`, {
      message,
    });
  }
}

export default new InvoiceCommentService();
