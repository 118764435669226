import { BaseService } from './base';

export interface PaymentSettings {
  message: string;
  success_message: string;
}

class PaymentSettingsService extends BaseService {
  async get(): Promise<PaymentSettings> {
    const { data } = await this.axios().get(`/settings/payment`);
    return data.data as PaymentSettings;
  }

  async update(updated: PaymentSettings): Promise<PaymentSettings> {
    const { data } = await this.axios().put(`/settings/payment`, updated);
    return data.data as PaymentSettings;
  }
}

export default new PaymentSettingsService();
