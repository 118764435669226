import { BaseService } from './base';
import { Invoice, InvoiceTextEmail } from '@/models';
import { format as formatDate } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';
import { extractFilename } from '@/lib/helpers';
import { Paginated } from '@/lib/types';

export type InvoiceSearchResult = Paginated<Invoice>;

interface InvoiceStateStatus {
  state: 'paid' | 'credited' | 'credit' | 'pastdue' | 'open';
}

interface InvoiceStatusSegment {
  status: boolean;
  message: string | null;
  time: string | null;
}

export interface InvoiceMailStatusEvent {
  recipient: string;
  // valid types: 'accepted' | 'delivered' | 'dropped' | 'open' | 'opened' | 'queued' | 'send' | 'sent'
  event: string;
  time: string;
}

interface InvoiceMailStatus {
  status: boolean;
  events: InvoiceMailStatusEvent[];
}

interface InvoiceAttachmentStatus {
  status: boolean;
  id: string | null;
  name: string | null;
}

interface InvoiceBookingStatus extends InvoiceStatusSegment {
  method: string;
}

interface InvoiceCollectionStatus {
  status: boolean;
  type: string | null;
  date: string | null;
}

interface InvoiceReminderStatus {
  status: boolean;
  count: number;
  time: string | null;
}

interface InvoiceRelationStatus {
  status: boolean;
  type: string;
  from: string;
  to: string;
  date: string;
}

export interface InvoiceCommentsStatusItem {
  user: {
    id: number;
    name: string;
  };
  time: string;
  message: string;
}

export interface InvoiceCommentsStatus {
  status: boolean;
  comments: InvoiceCommentsStatusItem[];
}

export interface InvoiceStatus {
  invoice: InvoiceStateStatus;
  booking: InvoiceBookingStatus;
  mail: InvoiceMailStatus;
  attachment: InvoiceAttachmentStatus;
  sepa: InvoiceStatusSegment;
  collection: InvoiceCollectionStatus;
  payment: InvoiceStatusSegment;
  reminder: InvoiceReminderStatus;
  recurring: InvoiceStatusSegment;
  quotation: InvoiceStatusSegment;
  relation: InvoiceRelationStatus;
  comments: InvoiceCommentsStatus;
}

export type InvoiceSearchType = 'invoice' | 'quotation' | 'recurring' | 'draft';
export const INVOICE_SEARCH_TYPES: InvoiceSearchType[] = [
  'invoice',
  'quotation',
  'recurring',
  'draft',
];

interface InvoiceSearchOptions {
  type?: InvoiceSearchType;
  order?: string;
  reverse?: boolean;
  limit?: number;
  status?: string | string[];
  term?: string;
  mindate?: Date;
  maxdate?: Date;
  minduedate?: Date;
  maxduedate?: Date;
  minamount?: number;
  maxamount?: number;
  minnumber?: string;
  maxnumber?: string;
  customer_id?: number;
  sepa_id?: number;
}

export interface InvoiceTotals {
  [currency: string]: {
    amount_incl: number;
    amount_excl: number;
  };
}

const defaultSearchOptions: InvoiceSearchOptions = {
  limit: 10,
  reverse: true,
};

export enum InvoiceSendType {
  Mark, // Mark as sent, no further action is taken
  Mail, // Mark as sent, send email
  Sms, // Mark as sent, send SMS
  Pdf, // Mark as sent, download PDF
  Queue, // Mark as sent, send SMS, bulk action, async
}

interface InvoiceSendOptions {
  text?: InvoiceTextEmail;
  shiftSendDate?: boolean;
}

class InvoiceService extends BaseService {
  async get(id: string): Promise<Invoice> {
    const { data } = await this.axios().get(`/invoice/${id}`);
    return Invoice.deserialize(data.data);
  }

  async search(
    page: number = 0,
    options: InvoiceSearchOptions = {},
  ): Promise<InvoiceSearchResult> {
    const { data } = await this.axios().get(`/invoice/search`, {
      params: {
        ...defaultSearchOptions,
        ...options,
        page,
      },
    });

    data.data.items = data.data.items.map(Invoice.deserialize);
    return data.data;
  }

  async searchTotals(
    options: InvoiceSearchOptions = {},
  ): Promise<InvoiceTotals> {
    const { data } = await this.axios().get(`invoice/totals`, {
      params: {
        ...defaultSearchOptions,
        ...options,
      },
    });

    return data.data;
  }

  async searchCsv(options: InvoiceSearchOptions = {}): Promise<File> {
    const response = await this.axios().get(`invoice/csv`, {
      params: {
        ...defaultSearchOptions,
        ...options,
      },
      responseType: 'blob',
    });

    const filename = extractFilename(response) || 'invoices.csv';

    return new File([response.data], filename, { type: 'text/csv' });
  }

  async status(id: string): Promise<InvoiceStatus> {
    const { data } = await this.axios().get(`/invoice/${id}/status`);
    return data.data;
  }

  async view(id: string, original: boolean = false): Promise<Blob> {
    const { data } = await this.axios().get(`/invoice/${id}/view`, {
      responseType: 'blob',
      params: {
        original,
      },
    });
    return data;
  }

  async create(invoice: Invoice): Promise<string> {
    const serialized = invoice.serialize();
    const { data } = await this.axios().post('/invoice', serialized);
    return data.data;
  }

  async update(invoice: Invoice): Promise<string> {
    const serialized = invoice.serialize();
    const { data } = await this.axios().put(
      `/invoice/${invoice.id}`,
      serialized,
    );
    return data.data;
  }

  async send(
    id: string,
    type: InvoiceSendType,
    options: InvoiceSendOptions = {},
  ): Promise<void> {
    let text;
    const params = {};
    switch (type) {
      case InvoiceSendType.Mark:
        if (options.shiftSendDate) {
          params['shiftSendDate'] = true;
        }
        await this.axios().post(
          `invoice/${id}/send`,
          {},
          {
            params,
          },
        );
        break;

      case InvoiceSendType.Mail:
        text = options.text;
        if (!text) throw new Error('Sending using mail requires emailtext');
        await this.axios().post(`invoice/${id}/send/mail`, text.serialize());
        break;

      case InvoiceSendType.Queue:
        text = options.text;
        if (!text) throw new Error('Sending using mail requires emailtext');
        await this.axios().post(`invoice/${id}/queue`, text.serialize());
        break;

      case InvoiceSendType.Sms:
        await this.axios().post(`invoice/${id}/send/sms`);
        break;

      default:
        throw new Error('Invalid invoice send type');
    }
  }

  async zip(id: string): Promise<Blob> {
    const { data } = await this.axios().get(`/invoice/${id}/zip`, {
      responseType: 'blob',
    });
    return data;
  }

  async downloadAttachment(id: string, attachmentId: string): Promise<Blob> {
    const { data } = await this.axios().get(
      `/invoice/${id}/attachment/${attachmentId}`,
      { responseType: 'blob' },
    );
    return data;
  }

  async sendDownloadPdf(id: string): Promise<File> {
    const response = await this.axios().post(
      `/invoice/${id}/send/pdf`,
      {},
      {
        responseType: 'blob',
      },
    );

    const filename = extractFilename(response) || 'invoice.pdf';

    return new File([response.data], filename, {
      type: response.data.type,
    });
  }

  async generateUbl(id: string): Promise<File> {
    const response = await this.axios().get(`/invoice/${id}/ubl`, {
      responseType: 'blob',
    });

    const filename = extractFilename(response) || 'invoice-ubl.xml';

    return new File([response.data], filename, {
      type: response.data.type,
    });
  }

  async sepa(ids: string[], date: Date): Promise<File> {
    const { data } = await this.axios().post(`/invoice/${ids.join(',')}/sepa`, {
      date: formatDate(date, DATE_FORMAT_ISO),
    });

    return new File([data.data], 'sepa.xml', { type: 'text/xml' });
  }

  async book(...ids: string[]) {
    await this.axios().post(`invoice/${ids.join(',')}/book`);
  }

  async resend(id: string, text: InvoiceTextEmail): Promise<void> {
    await this.axios().post(`invoice/${id}/resend`, text.serialize());
  }

  async credit(id: string): Promise<string> {
    const { data } = await this.axios().post(`invoice/${id}/credit`);
    return data.data;
  }

  async remind(id: string): Promise<void> {
    await this.axios().post(`invoice/${id}/remind`);
  }

  async markPaid(id: string, date: string, method: number): Promise<void> {
    await this.axios().post(`invoice/${id}/payment`, {
      date,
      method,
    });
  }

  async markUnpaid(id: string): Promise<void> {
    await this.axios().delete(`invoice/${id}/payment`);
  }

  async syncPayments(): Promise<void> {
    await this.axios().post(`invoice/syncpayments`);
  }

  async delete(...ids: string[]): Promise<void> {
    await this.axios().delete(`invoice/${ids.join(',')}`);
  }

  async quotationApprove(...ids: string[]): Promise<void> {
    await this.axios().post(`invoice/${ids.join(',')}/approve`);
  }

  async quotationArchive(...ids: string[]): Promise<void> {
    await this.axios().post(`invoice/${ids.join(',')}/archive`);
  }
}

export default new InvoiceService();
