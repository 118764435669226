import { autoserialize, Deserialize, Serialize } from 'cerialize';

export class Office {
  @autoserialize public code: string;
  @autoserialize public name: string;

  constructor(code: string, name: string) {
    this.code = code;
    this.name = name;
  }

  static deserialize(json: Record<string, unknown>): Office {
    return Deserialize(json, Office);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Office);
  }
}
