import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { IntegrationService } from '@/lib/services';

export class Integration {
  @autoserializeAs('name') public name: string;
  @autoserializeAs('office') public office: string;
  @autoserializeAs('enabled') public enabled: boolean;
  @autoserializeAs('option') public option: any;
  @autoserializeAs('free') public free: boolean;
  @autoserializeAs('config') public config: any;

  public async hydrateConfiguration(): Promise<void> {
    const initializedData = await IntegrationService.get(this.name);
    Object.assign(this, initializedData);
  }

  static deserialize(json: Record<string, unknown>): Integration {
    return Deserialize(json, Integration);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Integration);
  }
}
