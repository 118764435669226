export class TokenData {
  exp: number = 0;
  sub: number = 0;
  company: number = 0;
  role: AclRole = AclRole.Employee;
  is_accountant: boolean = false;
}

export class AuthState {
  token: string = '';
  data: TokenData = new TokenData();
}

export enum AclRole {
  Employee = 'employees',
  User = 'users',
  Accountant = 'accountant',
  Admin = 'admin',
}

export enum SubscriptionStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Demo = 'demo',
  Confirmed = 'confirmed',
  Grace = 'grace',
  Suspended = 'suspended',
}
