/**
 * Checks if browser supports the 'date' input type
 */
function isDateInputSupported() {
  const input = document.createElement('input');
  const value = 'a';
  input.setAttribute('type', 'date');
  input.setAttribute('value', value);
  return input.value !== value;
}

// Safari doesn't support it...
if (!isDateInputSupported()) {
  import('date-input-polyfill');
}
