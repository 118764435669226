import { BaseService } from './base';
import { Customer } from '@/models';
import { Paginated } from '@/lib/types';

interface CustomerSearchOptions {
  limit?: number;
  term?: string;
}

export type CustomerSearchResult = Paginated<Customer>;

export interface CheckVatResponse {
  name: string;
  street: string;
  city: string;
  zip: string;
  country: string;
  number: string;
}

interface ImportResponse {
  count: number;
}

const defaultSearchOptions: CustomerSearchOptions = {
  limit: 50,
};

class CustomerService extends BaseService {
  async all(): Promise<Customer[]> {
    const { data } = await this.axios().get(`customer`);
    return data.data.map(Customer.deserialize);
  }

  async search(
    page: number = 1,
    options: CustomerSearchOptions = {},
  ): Promise<CustomerSearchResult> {
    const { data } = await this.axios().get(`/customer/search`, {
      params: {
        ...defaultSearchOptions,
        ...options,
        page,
      },
    });

    data.data.items = data.data.items.map(Customer.deserialize);
    return data.data;
  }

  async allMergeGroups(
    term: string,
    group: string,
  ): Promise<Record<string, Customer[]>> {
    const { data } = await this.axios().get(`/customer/duplicates`, {
      params: { ...(term.length && { term }), group },
    });

    Object.keys(data.data).map((key: string) => {
      data.data[key] = data.data[key].map(Customer.deserialize);
    });
    return data.data;
  }

  async get(id: any): Promise<Customer> {
    const { data } = await this.axios().get(`customer/${id}`);
    return Customer.deserialize(data.data);
  }

  async create(cust: Customer): Promise<number> {
    const { data } = await this.axios().post(`customer`, cust.serialize());
    return data.data.id;
  }

  async update(cust: Customer): Promise<void> {
    await this.axios().put(`customer/${cust.id}`, cust.serialize());
  }

  async mergeCustomers(masterId: number, copies: number[]): Promise<void> {
    await this.axios().put(`customer/merge/${masterId}`, { copies });
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`customer/${id}`);
  }

  async checkVat(
    countryCode: string,
    vatNumber: string,
  ): Promise<CheckVatResponse> {
    const { data } = await this.axios().get(`customer/checkvat`, {
      params: {
        countryCode,
        vatNumber,
      },
    });
    return {
      country: countryCode,
      number: vatNumber,
      ...data.data,
    };
  }

  async bulkImport(customers: any[]): Promise<ImportResponse> {
    const { data } = await this.axios().post(`customer/import`, customers);
    return data.data;
  }
}

export default new CustomerService();
