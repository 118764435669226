import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { Invoice } from '@/models/invoice';
import Big from 'big.js';

export class SepaCollection {
  @autoserializeAs(String, 'id') public id: string;
  @autoserializeAs(String, 'sepa_id') public sepa_id: string;
  @autoserializeAs(String, 'creation_date') public creation_date: string;
  @autoserializeAs(String, 'collection_date') public collection_date: string;
  @autoserializeAs(Invoice, 'invoices') public invoices: Invoice[];

  /**
   * totalInclVat is the computed total for all the invoices in the collection
   *
   * @returns {number} Total amount over all invoices, including VAT
   */
  get totalInclVat(): Big {
    return this.invoices.reduce((s, e) => {
      return s.add(e.totalInclVat);
    }, new Big(0));
  }
  static deserialize(json: Record<string, unknown>): SepaCollection {
    return Deserialize(json, SepaCollection);
  }

  public serialize(): any {
    return Serialize(this, SepaCollection);
  }
}
