import { GetterTree } from 'vuex';
import { ThemeState } from './state';
import { ThemeColors } from '@/lib/themes';

export function colors(state: ThemeState): ThemeColors {
  return state.colors;
}

export default {
  colors,
} as GetterTree<ThemeState, any>;
