import { autoserialize, Deserialize, Serialize } from 'cerialize';
import { RawLocation } from 'vue-router';
import { Invoice, InvoiceStatus } from '@/models/invoice';
import { DEFAULT_I18N } from '@/plugins/i18n';

export interface SearchResultMap {
  [key: string]: SearchResult[];
}

export class SearchResult {
  @autoserialize type: string;
  @autoserialize ref: string;
  @autoserialize txt: string;
  @autoserialize matched: string;
  @autoserialize meta: string;

  get icon(): string {
    switch (this.type) {
      case 'customer':
        return 'person';
      case 'invoice':
        return 'assignment';
      default:
        return '';
    }
  }

  get displayMeta(): string {
    if (!this.meta) {
      return '';
    }

    if (this.type === 'customer' || this.type === 'customer_invoice') {
      return `${this.meta}`;
    }

    if (this.type === 'invoice') {
      const status = +this.meta as any as InvoiceStatus;
      const i18nKey = Invoice.statusTranslationKey(status);
      if (!i18nKey) {
        return '';
      }
      const type = DEFAULT_I18N.tc(i18nKey);
      return `(${type})`;
    }

    return '';
  }

  get route(): RawLocation {
    if (this.type === 'invoice') {
      return {
        name: 'archive',
        query: {
          type: 'invoice', // check if quotation or draft? but I cant with what I have
          invoice_id: this.ref,
        },
      };
    }

    if (this.type === 'customer_invoice') {
      return {
        name: 'archive',
        query: {
          type: 'invoice',
          customer_id: this.ref,
        },
      };
    }

    if (this.type === 'customer') {
      return {
        name: 'customers',
        query: {
          select: this.ref,
        },
      };
    }

    return {};
  }

  static deserialize(json: Record<string, unknown>): SearchResult {
    return Deserialize(json, SearchResult);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, SearchResult);
  }
}
