import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { IsEmail, IsNotEmpty, IsOptional } from 'class-validator';
import { InvoiceReceiver } from '@/models/invoice';
import { Currency } from '@/lib/currency';

const RECEIVER_SHARED_PROPERTIES = [
  'id',
  'name',
  'contact',
  'address',
  'zipcode',
  'city',
  'country',
  'debtorNumber',
  'vatNumber',
  'person',
  'currencyOverride',
  'expirationOverride',
  'email',
  'extraTextOverride',
  'extraTextBottomOverride',
  'languageOverride',
  'optional1',
  'optional2',
  'phone',
];

export const CUSTOMER_IMPORT_PROPERTIES = [
  'companyname',
  'companycontact',
  'person',
  'email',
  'ccaddress',
  'phone',
  'address',
  'postalcode',
  'city',
  'country',
  'vatnumber',
  'iban',
  'debtorNumber',
];

export const CUSTOMER_IMPORT_TRANSLATIONS = [
  'companyname',
  'contact',
  'person',
  'email',
  'ccemail',
  'phone',
  'address',
  'zipcode',
  'city',
  'country',
  'vatnumber',
  'iban',
  'debnumber',
];

export class Customer {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('companyname')
  @IsNotEmpty()
  public name: string = '';
  @autoserializeAs('companycontact') public contact: string | null = null;
  @autoserializeAs('person') public person: boolean = false;

  @autoserializeAs('email')
  @IsEmail()
  public email: string = '';
  @autoserializeAs('ccaddress')
  @IsOptional()
  public emailCC: string | null = null;
  @autoserializeAs('phone')
  @IsOptional()
  public phone: string | null = null;

  @autoserializeAs('address')
  public address: string = '';
  @autoserializeAs('postalcode')
  public zipcode: string = '';
  @autoserializeAs('city')
  public city: string = '';
  @autoserializeAs('country')
  public country: string = '';

  @autoserializeAs('crm_id') public crmId: string | null = null;
  @autoserializeAs('debnumber') public debtorNumber: string | null = null;
  @autoserializeAs('vatnumber') public vatNumber: string | null = null;

  @autoserializeAs('iban') public iban: string | null = null;
  @autoserializeAs('optionalfield_1') public optional1: string | null = null;
  @autoserializeAs('optionalfield_2') public optional2: string | null = null;

  @autoserializeAs('option_currency')
  public currencyOverride: Currency | null = null;
  @autoserializeAs('option_expiration') public expirationOverride:
    | number
    | null = null;
  @autoserializeAs('option_extratext') public extraTextOverride: string | null =
    null;
  @autoserializeAs('option_extratextbottom') public extraTextBottomOverride:
    | string
    | null = null;
  @autoserializeAs('option_language') public languageOverride: string | null =
    null;
  @autoserializeAs('invoice_count') public invoiceCount: number = 0;

  public get formattedAddress(): string[] {
    return [
      `${this.address}`,
      `${this.zipcode}, ${this.city}`,
      `${this.country}`,
    ];
  }

  static deserialize(json: Record<string, unknown>): Customer {
    return Deserialize(json, Customer);
  }

  public toInvoiceReceiver(): InvoiceReceiver {
    const r = new InvoiceReceiver();

    for (const prop of RECEIVER_SHARED_PROPERTIES) {
      r[prop] = this[prop];
    }

    return r;
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Customer);
  }
}
