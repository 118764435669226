import { Deserialize, Serialize } from 'cerialize';

export class InvoiceTextProfile {
  /**
   * The profile lines for the invoice. 12 of them.
   * @see Profile
   */
  public lines: string[] = [];

  /**
   * Path to the logo.
   */
  public logo: string;

  static deserialize(json: Record<string, unknown>): InvoiceTextProfile {
    return Deserialize(json, InvoiceTextProfile);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceTextProfile);
  }
}
