import { RouteConfig } from 'vue-router';
import { page } from './helper';

export const routes: RouteConfig[] = [
  { path: '', name: 'admin', redirect: 'companies' },
  {
    path: 'companies',
    name: 'admin-companies',
    component: () => page(import('@/views/admin/Companies.vue')),
  },
  {
    path: 'accountants',
    name: 'admin-accountants',
    component: () => page(import('@/views/admin/Accountants.vue')),
  },
  {
    path: 'users',
    name: 'admin-users',
    component: () => page(import('@/views/admin/Users.vue')),
  },
];

export default routes;
