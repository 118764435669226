import { BaseService } from './base';
import { SearchResult } from '@/models/SearchResult';

class SearchService extends BaseService {
  async search(term: string, limit: number = 3): Promise<SearchResult[]> {
    const { data } = await this.axios().get(`/search`, {
      params: {
        term,
        limit,
      },
    });
    return data.data.map(SearchResult.deserialize);
  }
}

export default new SearchService();
