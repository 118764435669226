import { BaseService } from './base';
import { Prognosis } from '@/models';

class PrognosisService extends BaseService {
  async get(): Promise<Prognosis | false> {
    const { data } = await this.axios().get(`/prognosis`);
    return Prognosis.deserialize(data.data);
  }

  async update(fields: Record<string, unknown>): Promise<Prognosis> {
    const { data } = await this.axios().put(`/prognosis`, fields);
    return Prognosis.deserialize(data.data);
  }
}

export default new PrognosisService();
