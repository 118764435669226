
















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Logo from '@/components/Logo.vue';

@Component({
  components: { Logo },
})
export default class BrandBar extends Vue {}
