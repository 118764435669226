import { BaseService } from './base';
import { SmsText } from '@/models';

export interface SmsCounts {
  last: number;
  current: number;
  total: number;
}

export interface SmsSettings {
  enabled: boolean;
}

class SmsService extends BaseService {
  async getSettings(): Promise<SmsSettings> {
    const { data } = await this.axios().get(`/settings/sms`);
    return data.data;
  }

  async updateSettings(settings: SmsSettings): Promise<SmsSettings> {
    const { data } = await this.axios().put(`/settings/sms`, settings);
    return data.data;
  }

  async counts(): Promise<SmsCounts> {
    const { data } = await this.axios().get(`/settings/sms/count`);
    return data.data;
  }

  async listTexts(): Promise<SmsText[]> {
    const { data } = await this.axios().get(`/settings/sms/text`);
    return data.data.map(SmsText.deserialize);
  }

  async getText(id: number): Promise<SmsText> {
    const { data } = await this.axios().get(`/settings/sms/text/${id}`);
    return SmsText.deserialize(data.data);
  }

  async createText(text: SmsText): Promise<SmsText> {
    const { data } = await this.axios().post(
      `/settings/sms/text`,
      text.serialize(),
    );
    return SmsText.deserialize(data.data);
  }

  async updateText(text: SmsText): Promise<SmsText> {
    const { data } = await this.axios().put(
      `/settings/sms/text/${text.id}`,
      text.serialize(),
    );
    return SmsText.deserialize(data.data);
  }

  async deleteText(id: number): Promise<void> {
    await this.axios().delete(`/settings/sms/text/${id}`);
  }
}

export default new SmsService();
