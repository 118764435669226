import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class EmailText {
  @autoserializeAs('id') public id: number;
  @autoserializeAs('name') public name: string;
  @autoserializeAs('subject') public subject: string;
  @autoserializeAs('text') public text: string;

  static deserialize(json: Record<string, unknown>): EmailText {
    return Deserialize(json, EmailText);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, EmailText);
  }
}
