import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { InvoiceTextEmail, InvoiceTextProfile } from '.';

export class InvoiceText {
  /**
   * Top text (Above the lines)
   */
  @autoserializeAs('top') public top: string;

  /**
   * Bottom text (Bottom of the invoice but above the footer)
   */
  @autoserializeAs('bottom') public bottom: string;

  /**
   * Footer text (Bottom of ?every page of? the invoice)
   */
  @autoserializeAs('footer') public footer: string;

  /**
   * Status text
   * TODO: figure out what this is for
   */
  @autoserializeAs('status') public status: string;

  @autoserializeAs('email') public email: InvoiceTextEmail | null;

  @autoserializeAs('profile') public profile: InvoiceTextProfile;

  constructor() {
    this.profile = new InvoiceTextProfile();
  }

  static deserialize(json: Record<string, unknown>): InvoiceText {
    return Deserialize(json, InvoiceText);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceText);
  }

  public static OnDeserialized(
    instance: InvoiceText,
    json: Record<string, any>,
  ): void {
    instance.email = InvoiceTextEmail.deserialize(json.email);
    instance.profile = InvoiceTextProfile.deserialize(json.profile);
  }
}
