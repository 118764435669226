import { Matomo } from '@/matomo';
import { TrackingEvent } from '@/lib/trackingevent';

enum CustomDimensions {
  AppVersion = 1,
  CompanyId = 3,
}

class MatomoService {
  instance: Matomo | null = null;

  public setMatomo(m: Matomo) {
    if (!m) return;
    this.instance = m;
    this.instance.setCustomDimension(
      CustomDimensions.AppVersion,
      process.env.VUE_APP_VERSION || 'unknown',
    );
  }

  // This is the only method that could technically race matomo, so wrap it in a retryer
  public setUserId(
    id: string,
    company: number | null = null,
    retries: number = 20,
  ) {
    if (this.instance) {
      this.instance.setUserId(id);
      if (company !== null) {
        this.instance.setCustomDimension(
          CustomDimensions.CompanyId,
          company.toString(),
        );
      }
    } else if (retries > 0) {
      setTimeout(() => this.setUserId(id, company, retries - 1), 100);
    }
  }

  public resetUserId() {
    if (this.instance) {
      this.instance.resetUserId();
    }
  }

  public trackEvent(event: TrackingEvent) {
    this.trackEventRaw(event.category, event.action, event.name, event.value);
  }

  public trackEventRaw(
    category: string,
    action: string,
    name?: string,
    value?: string,
  ) {
    if (this.instance) {
      // Add a try loop here **just in case**
      // We dont truly care if we mis an event, but we never want it to crash anyone
      try {
        this.instance.trackEvent(category, action, name, value);
      } catch (e) {
        // Ignored
      }
    }
  }
}

export default new MatomoService();
