import { round } from 'lodash-es';

export enum LegalForm {
  BV = 'BV',
  EZ_VOF = 'EZ/VOF',
}

// hack for select input returning strings
export enum TaxReturnFrequency {
  Monthly = '0',
  Quarterly = '1',
}

export class CoronaCosts {
  avgVATPercent: number = 0;
  avgPurchaseWorthPercent: number = 0;
  avgPersonnelCostPercent: number = 0;
  monthlyRent: number = 0;
  rentIncl: boolean = false;
  monthlyWriteOff: number = 0;
  legalForm: LegalForm = LegalForm.BV;
  managementFee: number = 0;
  privateWithdrawal: number = 0;
  beginYearBankBalance: number = 0;
  taxReturnFrequency: TaxReturnFrequency = TaxReturnFrequency.Quarterly;
  dueIncomeTax: number = 0;
  dueRevenueTax: number = 0;

  loanAmount: number = 0;
  loanTerm: number = 0;
  loanInterest: number = 0;

  static createDefault(): void {
    const costs = new CoronaCosts();
    costs.avgVATPercent = 12;
    costs.avgPurchaseWorthPercent = 31;
    costs.avgPersonnelCostPercent = 30;
    costs.monthlyRent = 4500;
    costs.rentIncl = false;
    costs.monthlyWriteOff = 900;
    costs.legalForm = LegalForm.BV;
    costs.managementFee = 2000;
    costs.privateWithdrawal = -1;
    costs.beginYearBankBalance = 48000;
    costs.taxReturnFrequency = TaxReturnFrequency.Quarterly;
    costs.dueIncomeTax = 33000;
    costs.dueRevenueTax = 54000;

    costs.loanAmount = 100000;
    costs.loanTerm = 60;
    costs.loanInterest = 0.04;
  }

  get avgVATFactor(): number {
    return this.avgVATPercent / 100;
  }

  get avgPurchaseWorthFactor(): number {
    return this.avgPurchaseWorthPercent / 100;
  }

  get avgPersonnelCostFactor(): number {
    return this.avgPersonnelCostPercent / 100;
  }

  set loanInterestHuman(v: number) {
    this.loanInterest = v / 100;
  }
  get loanInterestHuman(): number {
    return this.loanInterest * 100;
  }

  get loanAnnuity(): number {
    if (+this.loanTerm === 0 || this.loanAmount === 0) return 0;
    return round(
      PMT(this.loanInterest / 12, this.loanTerm, this.loanAmount, 0, 0),
      2,
    );
  }
}

// https://stackoverflow.com/questions/5294074/pmt-function-in-javascript
function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  const pvif = Math.pow(1 + ir, np);
  let pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}
