import { BaseService } from './base';
import { Company } from '@/models';

interface SubscribeData {
  url: string;
}

class CompanyService extends BaseService {
  async all(): Promise<Company[]> {
    const { data } = await this.axios().get(`/company`);
    return data.data.map(Company.deserialize);
  }

  async subscribe(): Promise<SubscribeData> {
    const { data } = await this.axios().post(`/subscription`, {
      callback: window.location.origin + '/confirm-subscription',
    });
    return data.data as SubscribeData;
  }

  async subscriptionStatus(): Promise<boolean> {
    try {
      await this.axios().get('/subscription');
      return true;
    } catch (e) {
      return false;
    }
  }
}

export default new CompanyService();
