import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { Attachment } from '@/models/Attachment';
import { EmailText } from '@/models/EmailText';

export class InvoiceTextEmail {
  /**
   * Subject for the email
   */
  @autoserializeAs('subject') public subject: string;

  /**
   * Body for the email
   */
  @autoserializeAs('body') public body: string;

  @autoserializeAs('attachments') public attachment: Attachment | null;

  static fromProfileEmailText(text: EmailText): InvoiceTextEmail {
    const instance = new InvoiceTextEmail();
    instance.subject = text.subject;
    instance.body = text.text;
    return instance;
  }

  get isEmpty(): boolean {
    return !this.subject && !this.body;
  }

  static deserialize(json: Record<string, unknown>): InvoiceTextEmail {
    return Deserialize(json, InvoiceTextEmail);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceTextEmail);
  }
}
