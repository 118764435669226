




































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SearchResult, SearchResultMap } from '@/models';
import { isEmpty } from 'lodash-es';

@Component({
  components: {},
})
export default class SearchResults extends Vue {
  @Prop({ required: true })
  public readonly results!: SearchResultMap;

  onClick(item: SearchResult): void {
    this.$emit('select', item);
  }

  get hasResults(): boolean {
    return !isEmpty(this.results);
  }
}
