import { BaseService } from './base';
import { PaymentMethod } from '@/models';

class PaymentMethodsService extends BaseService {
  async all(): Promise<PaymentMethod[]> {
    const { data } = await this.axios().get(`/settings/paymentmethods`);
    return data.data.map(PaymentMethod.deserialize);
  }

  async create(paymentMethod: PaymentMethod): Promise<PaymentMethod> {
    const { data } = await this.axios().post(
      `/settings/paymentmethods`,
      paymentMethod.serialize(),
    );
    return PaymentMethod.deserialize(data.data);
  }

  async update(paymentMethod: PaymentMethod): Promise<PaymentMethod> {
    const { data } = await this.axios().put(
      `/settings/paymentmethods/${paymentMethod.id}`,
      paymentMethod.serialize(),
    );
    return PaymentMethod.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/paymentmethods/${id}`);
  }
}

export default new PaymentMethodsService();
