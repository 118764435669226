import { Module, Plugin } from 'vuex';
import { AuthState } from './state';
import Mutations from './mutations';
import Actions from './actions';
import Getters from './getters';

export class Auth implements Module<AuthState, any> {
  namespaced: boolean = true;

  state = new AuthState();
  mutations = Mutations;
  actions = Actions;
  getters = Getters;

  plugins: Plugin<AuthState>[];

  // create everything
  constructor(plugins: Plugin<AuthState>[] = []) {
    this.plugins = plugins;
  }
}
