import { RouteConfig } from 'vue-router';
import { page } from './helper';

export const routes: RouteConfig[] = [
  { path: '', name: 'accountant', redirect: 'companies' },
  {
    path: 'companies',
    name: 'accountant-companies',
    component: () => page(import('@/views/accountant/Companies.vue')),
  },
  {
    path: 'users',
    name: 'accountant-users',
    component: () => page(import('@/views/accountant/Users.vue')),
  },
  {
    path: 'integrations',
    name: 'accountant-integrations',
    component: () => page(import('@/views/accountant/Integrations.vue')),
  },
  {
    path: 'settings',
    name: 'accountant-settings',
    component: () => page(import('@/views/accountant/Settings.vue')),
  },
];

export default routes;
