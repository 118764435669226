











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ActionButton extends Vue {
  @Prop({ default: '' })
  icon: string;

  click(e: Event): void {
    this.$emit('click', e);
  }
}
