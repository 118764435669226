import { BaseService } from './base';
import { Attachment } from '@/models';

class EmailAttachmentService extends BaseService {
  async all(): Promise<Attachment[]> {
    const { data } = await this.axios().get(`/settings/attachments`);
    return data.data.map(Attachment.deserialize);
  }

  async create(attachment: Attachment): Promise<Attachment> {
    const { data } = await this.axios().post(
      `/settings/attachments`,
      attachment.serialize(),
    );
    return Attachment.deserialize(data.data);
  }

  async update(attachment: Attachment): Promise<Attachment> {
    const { data } = await this.axios().put(
      `/settings/attachments/${attachment.id}`,
      attachment.serialize(),
    );
    return Attachment.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/attachments/${id}`);
  }
}

export default new EmailAttachmentService();
