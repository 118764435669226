import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';

export enum UserRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
}

export class User {
  @autoserializeAs('id') public id: number;

  @IsEmail()
  @autoserializeAs('email')
  public email: string;
  @IsNotEmpty()
  @autoserializeAs('name')
  public name: string;
  @IsNotEmpty()
  @autoserializeAs('role')
  public role: string;
  @IsNotEmpty()
  @autoserializeAs('language')
  public language: string;

  // Password, only for creation
  @IsNotEmpty()
  @MinLength(8)
  @autoserializeAs('password')
  public password: string;
  public confirmPassword: string;

  public static deserialize(json: Record<string, unknown>): User {
    return Deserialize(json, User);
  }

  public serialize(): any {
    return Serialize(this, User);
  }
}
