import { ActionTree, ActionContext } from 'vuex';
import { SettingState } from './state';

function set(
  store: ActionContext<SettingState, any>,
  newState: SettingState,
): void {
  store.commit('set', newState);
}

function setSmsEnabled(
  store: ActionContext<SettingState, any>,
  enabled: boolean,
): void {
  store.commit('setSmsEnabled', enabled);
}

function clear(store: ActionContext<SettingState, any>): void {
  store.commit('clear');
}

export default {
  set,
  setSmsEnabled,
  clear,
} as ActionTree<SettingState, any>;
