












import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';
import BrandBar from '@/components/BrandBar.vue';
import { namespace } from 'vuex-class';

import NotificationsManager from '@/lib/lilium';

import { Component, Watch } from 'vue-property-decorator';
import { MatomoService } from '@/lib/services';
import { ThemeColors, THEME_DARK, THEME_LIGHT } from '@/lib/themes';

const sAuth = namespace('auth');
const sTheme = namespace('theme');

@Component({
  components: {
    Navbar,
    BrandBar,
  },
})
export default class App extends Vue {
  @sAuth.Getter('isLoggedIn') isLoggedIn;
  @sTheme.Getter('colors') colors;
  @sTheme.Action('setColorScheme') setColorScheme: (arg: ThemeColors) => void;

  browserTheme: MediaQueryList = window.matchMedia(
    '(prefers-color-scheme: dark)',
  );

  created(): void {
    MatomoService.setMatomo(this.$matomo);
    NotificationsManager.setToaster(this.$toaster);
  }

  mounted(): void {
    this.setColorScheme(this.browserTheme.matches ? THEME_DARK : THEME_LIGHT);
    this.browserTheme.addEventListener('change', (e) => {
      this.setColorScheme(e.matches ? THEME_DARK : THEME_LIGHT);
    });
  }

  @Watch('colors', { deep: true })
  applyTheme(ts: ThemeColors): void {
    const se = document.documentElement.style;
    se.setProperty('--color-background', ts.background);
    se.setProperty('--color-background-card', ts.backgroundCard);

    se.setProperty('--color-primary', ts.primary);
    se.setProperty('--color-primary-dark', ts.primaryDark);
    se.setProperty('--color-primary-transparent', ts.primaryTransparent);

    se.setProperty('--color-text', ts.text);
    se.setProperty('--color-text-primary', ts.textPrimary);
    se.setProperty('--color-text-placeholder', ts.textPlaceholder);

    se.setProperty('--color-line', ts.line);
  }
}
