import { Deserialize, Serialize } from 'cerialize';

export class InvoiceLedger {
  public id: number;

  public name: string;

  public credit: string;

  public code: string;

  static deserialize(json: Record<string, unknown>): InvoiceLedger {
    return Deserialize(json, InvoiceLedger);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceLedger);
  }
}
