import {
  autoserialize,
  autoserializeAs,
  Deserialize,
  Serialize,
} from 'cerialize';

export class SmsText {
  @autoserialize public id: number;
  @autoserializeAs('company_id') public companyId: number;
  @autoserialize public name: string;
  @autoserialize public text: string;

  static deserialize(json: Record<string, unknown>): SmsText {
    return Deserialize(json, SmsText);
  }

  public serialize(): any {
    return Serialize(this, SmsText);
  }
}
