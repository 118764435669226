import { GetterTree } from 'vuex';
import { AclRole, AuthState } from './state';

export function isLoggedIn(state: AuthState): boolean {
  return !!state.token;
}

export function expiration(state: AuthState): number {
  return +state.data.exp;
}

export function expired(state: AuthState): boolean {
  const now = +new Date() / 1000;
  return now > expiration(state);
}

// Returns true if token expires in less than 5 minutes
export function nearlyExpired(state: AuthState): boolean {
  const now = +new Date() / 1000;
  return now > expiration(state) - 300;
}

export function user(state: AuthState): number {
  return +state.data.sub;
}

export function company(state: AuthState): number {
  return +state.data.company;
}

export function isAccountant(state: AuthState): boolean {
  return state.data.is_accountant || false;
}

export function isAdmin(state: AuthState): boolean {
  return state.data.role === AclRole.Admin || false;
}

export default {
  isLoggedIn,
  expiration,
  expired,
  nearlyExpired,
  user,
  company,
  isAccountant,
  isAdmin,
} as GetterTree<AuthState, any>;
