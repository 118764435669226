import {
  autoserializeAs,
  autoserialize,
  inheritSerialization,
  Deserialize,
  Serialize,
} from 'cerialize';
import { Company } from '@/models/Company';
import { Language } from '@/plugins/i18n';

@inheritSerialization(Company)
export class CompanyExt extends Company {
  @autoserialize
  public office: string;

  @autoserializeAs('autobooking')
  public autoBooking: boolean;

  @autoserializeAs('user_name')
  public userName: string;
  @autoserializeAs('user_email')
  public userEmail: string;

  @autoserializeAs('password')
  public userPassword: string;
  public userPasswordConfirm;

  @autoserializeAs('language')
  public language: string = Language.EN;

  @autoserializeAs('bookkeeping')
  public bookkeeping: string | null;

  static deserialize(json: Record<string, unknown>): CompanyExt {
    return Deserialize(json, CompanyExt);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, CompanyExt);
  }

  public static OnSerialized(
    company: CompanyExt,
    json: Record<string, unknown>,
  ): void {
    json['company_name'] = company.name;
  }
}
