import { BaseService } from './base';
import { AccountantExt, CompanyExt, User } from '@/models';
import { Paginated } from '@/lib/types';

class AdminService extends BaseService {
  public async getCompanies(
    page: number = 1,
    q?: string,
  ): Promise<Paginated<CompanyExt>> {
    const { data } = await this.axios().get(`/admin/company`, {
      params: {
        page,
        q,
      },
    });
    data.data.items = data.data.items.map(CompanyExt.deserialize);
    return data.data;
  }

  public async getAccountants(): Promise<AccountantExt[]> {
    const { data } = await this.axios().get(`/admin/accountant`);
    return data.data.map(AccountantExt.deserialize);
  }

  public async getUsers(): Promise<User[]> {
    const { data } = await this.axios().get(`/admin/user`);
    return data.data.map(User.deserialize);
  }

  public async updateCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/admin/company/${c.id}`, c.serialize());
  }

  public async updateAccountant(a: AccountantExt): Promise<void> {
    await this.axios().post(`/admin/accountant/${a.id}`, a.serialize());
  }

  public async createCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/admin/company`, c.serialize());
  }

  public async createAccountant(a: AccountantExt): Promise<void> {
    await this.axios().post(`/admin/accountant`, a.serialize());
  }

  public async linkCompanyAccountant(
    a: AccountantExt,
    c: CompanyExt,
  ): Promise<void> {
    await this.axios().put(`/admin/accountant/${a.id}/${c.id}`);
  }
}

export default new AdminService();
