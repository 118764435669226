/* eslint-disable no-console */

// This used to be a serviceworker implementation, for now, we will nuke it in
// its entirety
if (window.navigator && 'serviceWorker' in window.navigator) {
  window.navigator.serviceWorker
    .getRegistrations()
    .then((registrations: readonly ServiceWorkerRegistration[]) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
}
