import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class Accountant {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('name') public name: string = '';
  @autoserializeAs('address') public address: string = '';
  @autoserializeAs('city') public city: string = '';
  @autoserializeAs('postalcode') public postalCode: string = '';
  @autoserializeAs('coc') public coc: string = '';

  @autoserializeAs('companies') public companies: string = '0';

  @autoserializeAs('status') public readonly status?: string;

  static deserialize(json: Record<string, unknown>): Accountant {
    return Deserialize(json, Accountant);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Accountant);
  }
}
