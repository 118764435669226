export default class Lilium {
  private readonly ws: WebSocket;
  private ready: boolean = false;
  private queue: Record<string, unknown>[] = [];
  private handlers: {
    [s: string]: (data: Record<string, unknown>) => void;
  } = {};

  constructor(url: string, token: string) {
    this.ws = new WebSocket(url + '?token=' + token);
    this.ws.onopen = (e: Event) => this.onOpen(e);
    this.ws.onclose = (e: CloseEvent) => this.onClose(e);
    this.ws.onmessage = (e: MessageEvent) => this.onMessage(e);
  }

  public register(
    event: string,
    handler: (data: Record<string, unknown>) => void,
  ): void {
    this.handlers[event] = handler;
  }

  public unregister(event: string): void {
    if (event in this.handlers) {
      delete this.handlers[event];
    }
  }

  public send(data: Record<string, unknown>): void {
    if (this.ready) {
      this.ws.send(JSON.stringify(data));
    } else {
      this.queue.push(data);
    }
  }

  public isReady(): boolean {
    return this.ready;
  }

  public getSocket(): WebSocket {
    return this.ws;
  }

  public close(): void {
    this.ws.close();
  }

  private onOpen(event: Event): void {
    void event;
    this.ready = true;
    for (const message of this.queue) {
      this.send(message);
    }
    this.queue = [];
  }

  private onClose(event: CloseEvent): void {
    void event;
    this.ready = false;
  }

  private onMessage(event: MessageEvent) {
    const message: { type: string; data: Record<string, unknown> } = JSON.parse(
      event.data,
    );
    if (message.type in this.handlers) {
      this.handlers[message.type](message.data);
    }
  }
}
