import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class PaymentMethod {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('name') public name: string = '';

  static deserialize(json: Record<string, unknown>): PaymentMethod {
    return Deserialize(json, PaymentMethod);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, PaymentMethod);
  }
}
