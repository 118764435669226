import { CoronaData } from '@/lib/corona/index';
import {
  ExploitationColumn,
  getExploitationColumn,
} from '@/lib/corona/exploitation_column';
import { TaxReturnFrequency } from '@/lib/corona/costs';

export class ExploitationMatrix {
  columns: ExploitationColumn[];

  constructor(data: CoronaData, year: number) {
    this.columns = [];

    for (let i = 0; i < 12; i++) {
      const month: number = data.monthForIndex(i);
      this.columns.push(
        getExploitationColumn(
          data,
          year,
          month,
          i,
          this.columns.map((e: ExploitationColumn) => e.annuity),
        ),
      );
    }
  }

  getRow(field: string): any[] {
    return this.columns.map((e: ExploitationColumn) => e[field]);
  }

  getTotal(field: string): number {
    return this.columns.reduce(
      (acc: number, e: ExploitationColumn) => (acc += e[field]),
      0,
    );
  }

  getMonth(monthIndex: number): ExploitationColumn {
    return this.columns[monthIndex];
  }

  // This is horrible and hacky, but we need a prototype by tonight.
  // I take full responsibility for this monstrosity.
  getRevenueTax(
    monthIndex: number,
    month: number,
    vatFactor: number,
    frequency: TaxReturnFrequency,
  ): number {
    if (monthIndex === 0) {
      // Can't have revenue tax without any preceding months.
      return 0;
    }

    if (frequency === TaxReturnFrequency.Monthly) {
      const monthData = this.getMonth(monthIndex - 1);
      return monthData.computeRevenueTax(vatFactor);
    }
    // frequency is Quarterly. Check if we're at a divisible-by-three month
    if (month % 3 !== 0) {
      return 0;
    }

    let totalRevenueTax = 0;
    if (monthIndex - 3 >= 0) {
      const m1 = this.getMonth(monthIndex - 3);
      totalRevenueTax += m1.computeRevenueTax(vatFactor);
    }

    if (monthIndex - 2 >= 0) {
      const m2 = this.getMonth(monthIndex - 2);
      totalRevenueTax += m2.computeRevenueTax(vatFactor);
    }

    if (monthIndex - 1 >= 0) {
      const m3 = this.getMonth(monthIndex - 1);
      totalRevenueTax += m3.computeRevenueTax(vatFactor);
    }

    return totalRevenueTax;
  }
}
