import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export type EmailAddressType = 'cc' | 'from';

export class EmailAddress {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('type') public type: EmailAddressType;
  @autoserializeAs('email') public address: string;
  @autoserializeAs('verified') public verified: boolean;

  get uniqueId(): string {
    return `${this.type}-${this.id}`;
  }

  static deserialize(json: Record<string, unknown>): EmailAddress {
    return Deserialize(json, EmailAddress);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, EmailAddress);
  }
}
