const MAX_RECURSE = 2;

export class Position {
  public x: number;
  public y: number;

  constructor(x: number, y: number, child: DOMRect) {
    this.y = y;

    // For tiny screens none of our calculations will be correct,
    // so we should check here if the best choice is to stick the popover to the left
    if (child.width >= BoundsCalculator.bodyWidth - 10) {
      this.x = 0;
    } else {
      this.x = x;
    }
  }
}

export class BoundsCalculator {
  public static get bodyHeight(): number {
    return document.body.getBoundingClientRect().height;
  }

  public static get bodyWidth(): number {
    return document.body.getBoundingClientRect().width;
  }

  public static supportedPositions(): string[] {
    return [
      'bottom_right',
      'bottom',
      'bottom_left',
      'top_right',
      'top',
      'top_left',
      'left_bottom',
      'left',
      'left_top',
      'right_bottom',
      'right',
      'right_top',
    ];
  }

  public static bottom_right(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.left + root.width - child.width,
      root.bottom,
      child,
    );

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.top_right(root, child, step + 1);
    }

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.bottom_left(root, child, step + 1);
    }

    return pos;
  }

  public static bottom(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.left + root.width / 2 - child.width / 2,
      root.bottom,
      child,
    );

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.top(root, child, step + 1);
    }

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.bottom_left(root, child, step + 1);
    }

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.bottom_right(root, child, step + 1);
    }

    return pos;
  }

  public static bottom_left(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(root.left, root.bottom, child);

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.top_left(root, child, step + 1);
    }

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.bottom_right(root, child, step + 1);
    }

    return pos;
  }

  public static top_right(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.left + root.width - child.width,
      root.top - child.height,
      child,
    );

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.bottom_right(root, child, step + 1);
    }

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.top_left(root, child, step + 1);
    }

    return pos;
  }

  public static top(root: DOMRect, child: DOMRect, step: number = 0): Position {
    const pos = new Position(
      root.left + root.width / 2 - child.width / 2,
      root.top - child.height,
      child,
    );

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.bottom(root, child, step + 1);
    }

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.top_left(root, child, step + 1);
    }

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.top_right(root, child, step + 1);
    }

    return pos;
  }

  public static top_left(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(root.left, root.top - child.height, child);

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.bottom_left(root, child, step + 1);
    }

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.top_right(root, child, step + 1);
    }

    return pos;
  }

  public static left_bottom(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.left - child.width,
      root.bottom - child.height,
      child,
    );

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.right_bottom(root, child, step + 1);
    }

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.left_top(root, child, step + 1);
    }

    return pos;
  }

  public static left(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.left - child.width,
      root.top + root.height / 2 - child.height / 2,
      child,
    );

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.right(root, child, step + 1);
    }

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.left_top(root, child, step + 1);
    }

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.left_bottom(root, child, step + 1);
    }

    return pos;
  }

  public static left_top(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(root.left - child.width, root.top, child);

    if (pos.x < 0 && step < MAX_RECURSE) {
      return this.right_top(root, child, step + 1);
    }

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.left_bottom(root, child, step + 1);
    }

    return pos;
  }

  public static right_bottom(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(root.right, root.bottom - child.height, child);

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.left_bottom(root, child, step + 1);
    }

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.right_top(root, child, step + 1);
    }

    return pos;
  }

  public static right(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(
      root.right,
      root.top + root.height / 2 - child.height / 2,
      child,
    );

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.left(root, child, step + 1);
    }

    if (pos.y < 0 && step < MAX_RECURSE) {
      return this.right_top(root, child, step + 1);
    }

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.right_bottom(root, child, step + 1);
    }

    return pos;
  }

  public static right_top(
    root: DOMRect,
    child: DOMRect,
    step: number = 0,
  ): Position {
    const pos = new Position(root.right, root.top, child);

    if (pos.x + child.width > this.bodyWidth && step < MAX_RECURSE) {
      return this.left_top(root, child, step + 1);
    }

    if (pos.y + child.height > this.bodyHeight && step < MAX_RECURSE) {
      return this.right_bottom(root, child, step + 1);
    }

    return pos;
  }
}
