import { BaseService } from './base';
import { Paginated } from '@/lib/types';
import { SepaCollection } from '@/models';

export type SepaSearchResult = Paginated<SepaCollection>;

interface SepaSearchOptions {
  order?: string;
  reverse?: boolean;
  limit?: number;
}

interface SepaTotals {
  [currency: string]: {
    amount_incl: number;
    amount_excl: number;
  };
}

const defaultSearchOptions: SepaSearchOptions = {
  limit: 10,
  reverse: true,
};

class SepaService extends BaseService {
  async search(
    page: number = 0,
    options: SepaSearchOptions = {},
  ): Promise<SepaSearchResult> {
    const { data } = await this.axios().get(`/sepa/search`, {
      params: {
        ...defaultSearchOptions,
        ...options,
        page,
      },
    });

    data.data.items = data.data.items.map(SepaCollection.deserialize);
    return data.data;
  }

  async searchTotals(options: SepaSearchOptions = {}): Promise<SepaTotals> {
    const { data } = await this.axios().get(`/sepa/totals`, {
      params: {
        ...defaultSearchOptions,
        ...options,
      },
    });

    return data.data;
  }

  async view(id: string): Promise<File> {
    const { data } = await this.axios().get(`/sepa/${id}/view`);

    return new File([data.data], 'sepa.xml', { type: 'text/xml' });
  }
}

export default new SepaService();
