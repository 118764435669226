import { BaseService } from './base';

class TermsAndConditionsService extends BaseService {
  async exists(): Promise<boolean> {
    try {
      await this.axios().head('/settings/termsandconditions');
      return true;
    } catch (e) {
      return false;
    }
  }

  async delete(): Promise<void> {
    await this.axios().delete('/settings/termsandconditions');
  }

  async upload(file: File): Promise<void> {
    const fd = new FormData();
    fd.append('file', file);
    await this.axios().post('/settings/termsandconditions', fd);
  }

  async get(): Promise<Blob> {
    const { data } = await this.axios().get('/settings/termsandconditions', {
      responseType: 'blob',
    });
    return data.data as Blob;
  }
}

export default new TermsAndConditionsService();
