import { BaseService } from './base';

export interface TwoFactorStatus {
  status: 'disabled' | 'connecting' | 'enabled';
}

export interface TwoFactorConnectResponse {
  secret: string;
  url: string;
}

export type TwoFactorCodesResponse = string[];

class TwoFactorService extends BaseService {
  async status(): Promise<TwoFactorStatus> {
    const { data } = await this.axios().get(`/settings/2fa`);
    return data.data as TwoFactorStatus;
  }

  async generateSecret(): Promise<TwoFactorConnectResponse> {
    const { data } = await this.axios().post(`/settings/2fa`);
    return data.data as TwoFactorConnectResponse;
  }

  async verifyConnect(code: string): Promise<void> {
    await this.axios().put(`/settings/2fa`, {
      code,
    });
  }

  async generateRecoveryCodes(): Promise<TwoFactorCodesResponse> {
    const { data } = await this.axios().post(`/settings/2fa/recovery`);
    return data.data as TwoFactorCodesResponse;
  }

  async remove(): Promise<void> {
    await this.axios().delete(`/settings/2fa`);
  }
}

export default new TwoFactorService();
