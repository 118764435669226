import { BaseService } from './base';
import { InvoiceService } from '.';

import { startOfMonth } from 'date-fns';

export type AnalyticsInterval = 'month' | 'year';
interface DataPoints {
  labels: string[];
  values: number[];
}

interface CustomerData {
  customerid: number;
  companyname: string;
  amount: number;
}

class AnalyticsService extends BaseService {
  async turnover(interval: AnalyticsInterval = 'month'): Promise<DataPoints> {
    const { data } = await this.axios().get(`/analytics/turnover/${interval}`);
    return data.data;
  }

  async customers(count: number = 10): Promise<CustomerData[]> {
    const { data } = await this.axios().get(`/analytics/customers/${count}`);
    return data.data;
  }

  async invoicesSentMonth() {
    const result = await InvoiceService.search(0, {
      type: 'invoice',
      limit: 0,
      mindate: startOfMonth(new Date()),
    });

    return result.total_items;
  }

  async invoicesOpen() {
    const result = await InvoiceService.search(0, {
      type: 'invoice',
      limit: 0,
      status: 'open',
    });

    return result.total_items;
  }

  async quotationsOpen() {
    const result = await InvoiceService.search(0, {
      type: 'quotation',
      limit: 0,
      status: 'open',
    });

    return result.total_items;
  }
}

export default new AnalyticsService();
