import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class Attachment {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('name')
  public name: string = '';

  @autoserializeAs('filename')
  public filename: string = '';

  @autoserializeAs('content')
  public content: string | null = null;

  static deserialize(json: Record<string, unknown>): Attachment {
    return Deserialize(json, Attachment);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Attachment);
  }
}
