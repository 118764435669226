import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class Product {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('description') public description: string = '';
  @autoserializeAs('vat_id') public vatId: number = 0;
  @autoserializeAs('price') public price: number = 0;
  @autoserializeAs('ledgernumber_id') public ledgerNumberId: number = 0;

  static deserialize(json: Record<string, unknown>): Product {
    return Deserialize(json, Product);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Product);
  }
}
