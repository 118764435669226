import { Module, Plugin } from 'vuex';
import { ThemeState } from '@/store/modules/theme/state';
import Mutations from './mutations';
import Actions from './actions';
import Getters from './getters';

export class Theme implements Module<ThemeState, any> {
  namespaced: boolean = true;

  state = new ThemeState();
  mutations = Mutations;
  actions = Actions;
  getters = Getters;

  plugins: Plugin<ThemeState>[];

  // create everything
  constructor(plugins: Plugin<ThemeState>[] = []) {
    this.plugins = plugins;
  }
}
