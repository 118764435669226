import { ThemeColors, THEME_LIGHT } from '@/lib/themes';
import { isNil, omitBy } from 'lodash-es';

export class ThemeState {
  logo: string | null = null;
  colors: ThemeColors = Object.assign({}, THEME_LIGHT);
  assign(other: ThemeState): ThemeState {
    this.logo = other.logo;
    Object.assign(this.colors, omitBy(other.colors, isNil));
    return this;
  }
}
