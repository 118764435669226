import { ActionTree, ActionContext } from 'vuex';
import { TokenData, AuthState } from './state';
import { decodeToken } from '@/lib/jwt';

type Context = ActionContext<AuthState, any>;

async function set(store: Context, token: string): Promise<void> {
  const data: TokenData = decodeToken(token);
  if (+new Date() / 1000 > data.exp) {
    throw new Error('Token has expired');
  }
  store.commit('setToken', token);
  store.commit('setData', data);
}

function clear(store: Context): void {
  store.commit('clear');
}

function check(store: Context): void {
  if (store.getters.expired) {
    store.commit('clear');
  }
}

export default {
  set,
  clear,
  check,
} as ActionTree<AuthState, any>;
