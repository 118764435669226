export const DATE_FORMAT_ISO = 'YYYY-MM-DD';
export const DATE_FORMAT_NEN = 'DD-MM-YYYY';
export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DD[T]HH:mm:ssZZ';
export const INVOICE_FILTER_STATUSES = [
  'open',
  'paid',
  'pastdue',
  'credit',
  'credited',
];

// TODO: this seemed like the most sensible place
// to put these constants, feel free to nuke if it isn't.
export const DEFAULT_LOCALE = 'nl-NL';
export const DEFAULT_LOCALE_OPTIONS = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

// Redefinitions of @types/big.js enum as ts-node hates it
// see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/29864
export const BIG_ROUND_DOWN = 0;
export const BIG_ROUND_HALF_UP = 1;
export const BIG_ROUND_HALF_EVEN = 2;
export const BIG_ROUND_UP = 3;
