import { BaseService } from './base';
import { EmailText } from '@/models';

class EmailTextService extends BaseService {
  async all(): Promise<EmailText[]> {
    const { data } = await this.axios().get(`/settings/email/text`);
    return data.data.map(EmailText.deserialize);
  }

  async get(id: number): Promise<EmailText> {
    // TODO: actually have a proper endpoint for this? Pretty please
    const all = await this.all();

    const text = all.find((e: EmailText) => +e.id === id);
    if (!text) {
      throw new Error('EmailText not found');
    }

    return text;
  }

  async create(emailText: EmailText): Promise<EmailText> {
    const { data } = await this.axios().post(
      `/settings/email/text`,
      emailText.serialize(),
    );
    return EmailText.deserialize(data.data);
  }

  async update(text: EmailText): Promise<EmailText> {
    const { data } = await this.axios().put(
      `/settings/email/text/${text.id}`,
      text.serialize(),
    );
    return EmailText.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/email/text/${id}`);
  }
}

export default new EmailTextService();
