import { BaseService } from './base';
import { InvoiceVAT } from '@/models';

class VatCodeService extends BaseService {
  async all(): Promise<InvoiceVAT[]> {
    const { data } = await this.axios().get(`/settings/vatcodes`);
    return data.data.map(InvoiceVAT.deserialize);
  }

  async get(id: number): Promise<InvoiceVAT> {
    void id;
    throw new Error('Unimplemented: Server cannot handle request');
  }

  async create(vc: InvoiceVAT): Promise<InvoiceVAT> {
    const { data } = await this.axios().post(
      `/settings/vatcodes`,
      vc.serialize(),
    );
    return InvoiceVAT.deserialize(data.data);
  }

  async update(vc: InvoiceVAT): Promise<InvoiceVAT> {
    const { data } = await this.axios().put(
      `/settings/vatcodes/${vc.id}`,
      vc.serialize(),
    );
    return InvoiceVAT.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/vatcodes/${id}`);
  }
}

export default new VatCodeService();
