import { CoronaData } from '@/lib/corona/index';
import {
  LiquidityColumn,
  getLiquidityColumn,
} from '@/lib/corona/liquidity_column';

export class LiquidityMatrix {
  columns: LiquidityColumn[];

  constructor(data: CoronaData, year: number) {
    this.columns = [];

    let startingBalance = 0;
    if (year === 0) {
      startingBalance = data.costs.beginYearBankBalance;
    } else {
      // Special case, first month of new year needs previous year's closing bank balance
      const first_year_matrix = data.liquidityMatrix(0);
      startingBalance = first_year_matrix.getMonth(11).closingBankBalance;
    }

    for (let i = 0; i < 12; i++) {
      const month = data.monthForIndex(i);
      this.columns.push(
        getLiquidityColumn(
          data,
          year,
          month,
          i,
          i === 0 ? startingBalance : this.columns[i - 1].closingBankBalance,
        ),
      );
    }
  }

  getRow(field: string): any[] {
    return this.columns.map((e: LiquidityColumn) => e[field]);
  }

  getMonth(monthIndex: number): LiquidityColumn {
    return this.columns[monthIndex];
  }

  getTotal(field: string): number {
    return this.columns.reduce(
      (acc: number, e: LiquidityColumn) => (acc += e[field]),
      0,
    );
  }
}
