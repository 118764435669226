import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class Company {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('name') public name: string = '';
  @autoserializeAs('address') public address: string = '';
  @autoserializeAs('city') public city: string = '';
  @autoserializeAs('postalcode') public postalCode: string = '';
  @autoserializeAs('coc') public coc: string = '';

  @autoserializeAs('logo') public logo: string | null;
  public renderedLogo: string | null = null;

  @autoserializeAs('next_number') public readonly nextInvoiceNumber: string;
  @autoserializeAs('next_qnumber') public readonly nextQuotationNumber: string;
  @autoserializeAs('package') public readonly package?: string;
  @autoserializeAs('status') public readonly status?: string;
  @autoserializeAs('accountant') public readonly accountant?: string;

  static deserialize(json: Record<string, unknown>): Company {
    return Deserialize(json, Company);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Company);
  }
}
