import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

/**
 * Type of VAT being levied.
 */
export enum InvoiceVATType {
  // Low tax
  LOW = 'low',
  // High tax
  HIGH = 'high',
  // Exempt from tax
  EXEMPT = 'exempt',
  // Untaxed
  NONE = 'none',
  // Export services within EU
  ICPS = 'ICPS',
  // Export goods within EU
  ICPG = 'ICPG',
  // Export outside the EU
  EBU = 'EBU',
}

export class InvoiceVAT {
  /**
   * Id for this VAT type
   */
  @autoserializeAs('id') public id: number;

  /**
   * Name for this VAT type
   */
  @autoserializeAs('name') public name: string;

  /**
   * VAT code for this VAT type
   */
  @autoserializeAs('code') public code: string;

  /**
   * VAT factor for this VAT type
   */
  @autoserializeAs('factor') public factor: number;

  /**
   * Type of VAT being levied.
   */
  @autoserializeAs('type') public type: InvoiceVATType;

  get percentage(): number {
    return Math.round((this.factor - 1) * 1000) / 10;
  }

  set percentage(v: number) {
    this.factor = 1 + v / 100;
  }

  static deserialize(json: Record<string, unknown>): InvoiceVAT {
    return Deserialize(json, InvoiceVAT);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceVAT);
  }
}
