import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

export class InvoiceSender {
  /**
   * Uniquely identifies an invoice sender through UUID
   */
  @autoserializeAs('id') public id: string;

  /**
   * Name of the sender
   */
  @autoserializeAs('name') public name: string;

  /**
   * Address of the sender
   */
  @autoserializeAs('address') public address: string;

  /**
   * Zip code of the sender
   */
  @autoserializeAs('postalCode') public zipCode: string;

  /**
   * City the sender resides in
   */
  @autoserializeAs('city') public city: string;

  /**
   * Country the sender resides in
   */
  @autoserializeAs('country') public country: string;

  /**
   * Chamber of Commerce code for the sender
   */
  @autoserializeAs('coc') public coc: string | null;

  constructor(
    name: string,
    address: string,
    zipcode: string,
    city: string,
    country: string,
    coc: string | null = null,
    profileId: string = '',
  ) {
    this.name = name;
    this.address = address;
    this.zipCode = zipcode;
    this.city = city;
    this.country = country;
    this.coc = coc;
    this.id = profileId;
  }

  /**
   * Formats the address as humans do
   *
   * @returns {string[]} List containing the formatted address line by line
   *
   */
  public get formattedAddress(): string[] {
    return [`${this.address}`, `${this.zipCode}, ${this.city}`];
  }

  static deserialize(json: Record<string, unknown>): InvoiceSender {
    return Deserialize(json, InvoiceSender);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceSender);
  }
}
