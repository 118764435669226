import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '@/router';
import store from '@/store';

import TnfConfig from '@/config';
import { Registration } from '@/models';
import { MatomoService } from '@/lib/services';
import NotificationsManager from '@/lib/lilium';
import * as Sentry from '@sentry/vue';
import { loadLanguageAsync, toLanguage } from '@/plugins/i18n';
import { currentAddress } from '@/lib/helpers';
import { ApiErrorCode } from '@/lib/constants/apierrorcode';
import { applyThemeInfo } from '@/lib/themes';
import { SettingState } from '@/store/modules/settings/state';
import { AuthState } from '@/store/modules/auth/state';
import * as AuthGetters from '@/store/modules/auth/getters';

const LOGIN: string = '/session/jwt';

class AuthService {
  public isInitialized: boolean = false;

  private retrievingNewToken: boolean = false;
  private preLoginToken: string | null = null;

  constructor() {
    if (this.localToken) {
      this.authenticate(this.localToken)
        .then(() => (this.isInitialized = true))
        .catch(() => (this.isInitialized = true));
    }
  }

  get isAuthenticated() {
    return store.getters['auth/isLoggedIn'];
  }

  get activeToken(): string {
    return store.state.auth.token;
  }

  get localToken(): string {
    return localStorage.getItem('auth_token') || '';
  }

  set localToken(token: string) {
    localStorage.setItem('auth_token', token);
  }

  public async authenticate(token: string) {
    await store.dispatch('auth/set', token);

    this.localToken = this.activeToken;
    await this.getPreferences().then((prefs: SettingState) => {
      const userdata = prefs.user_data || {};
      const userId: number = store.getters['auth/user'];
      const company_id: number = store.getters['auth/company'];

      Sentry.setUser({
        id: String(company_id),
        company_name: prefs.company_name,
        user_id: userId,
        user_name: userdata.name,
        email: userdata.email,
      });

      MatomoService.setUserId(userdata.email, store.getters['auth/company']);

      const promise = store.dispatch('settings/set', prefs);
      if (
        !prefs.subscription.satisfied &&
        router.currentRoute.name !== 'confirm-subscription'
      ) {
        router.push({ name: 'confirm-subscription' });
      }
      loadLanguageAsync(toLanguage(prefs.language));
      applyThemeInfo(prefs.theme || {});

      return promise;
    });
    // Async but we dont care about this being done immediately
    this.liliumToken()
      .then(NotificationsManager.connect)
      .catch(NotificationsManager.onFailed);
  }

  public async deauthenticate() {
    await store.dispatch('auth/clear');
    this.localToken = '';
    Sentry.setUser({});
    MatomoService.resetUserId();
    NotificationsManager.disconnect();
    await store.dispatch('theme/clear');
  }

  public getAxios() {
    const headers = {};
    if (this.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.activeToken}`;
    }
    const client = axios.create({
      headers,
      baseURL: TnfConfig.api.base,
    });

    if (this.isAuthenticated) {
      client.interceptors.request.use(this.beforeRequestHandler.bind(this));
    }

    return client;
  }

  public async login(
    email: string,
    password: string,
    captcha: string,
  ): Promise<boolean> {
    try {
      const { data } = await this.getAxios().post(LOGIN, {
        email,
        password,
        captcha,
      });
      await this.authenticate(data.data.token);
      return true;
    } catch (e) {
      // Two special options!
      // if 401 => General auth failure
      // if 403 (&& code = 12 (LoginRequireAdditional)) we need a totp code!

      // It is an actual axios error
      if ((e as any).response) {
        // Fancy typings here
        const response = (e as any).response as AxiosResponse<{
          error: { code: ApiErrorCode; message: string };
          data: { token: string };
        }>;

        if (response.data.error.code === ApiErrorCode.LoginRequiresAdditional) {
          this.preLoginToken = response.data.data.token;
          await router.push({
            name: 'login-2fa',
            query: router.currentRoute.query,
          });
          return false;
        }
        // Do we have a server-side message to display?
        if (response.data.error.message) {
          throw new Error(response.data.error.message);
        }
      }
      throw new Error('Invalid username / password');
    }
  }

  public async login2fa(
    totpCode: string,
    recoveryCode: string,
  ): Promise<boolean> {
    try {
      const { data } = await this.getAxios().post('/session/2fa', {
        token: this.preLoginToken,
        totp_code: totpCode,
        recovery_code: recoveryCode.toLowerCase(),
      });

      await this.authenticate(data.data.token);
      return true;
    } catch (e) {
      throw new Error('Invalid 2fa code');
    }
  }

  public async logout(route?: any) {
    await this.deauthenticate();
    await router.push(route ? route : '/login');
  }

  public async switchCompany(companyId: number) {
    try {
      const { data } = await this.getAxios().post('/session/jwt/switch', {
        company: companyId,
      });

      const oldUser = store.getters['auth/user'];
      await this.deauthenticate();
      await this.authenticate(data.data.token);
      const newUser = store.getters['auth/user'];

      Sentry.addBreadcrumb({
        message: 'Changed company',
        category: 'auth',
        data: {
          from: oldUser,
          to: newUser,
        },
      });
    } catch (e) {
      throw new Error('Session expired or no access to company');
    }
  }

  public async register(registration: Registration) {
    const { data } = await this.getAxios().post(
      '/register',
      registration.serialize(),
    );
    await this.authenticate(data.data.token);
  }

  public async changePassword(old: string, next: string): Promise<void> {
    await this.getAxios().post('/session/password', { old, new: next });
  }

  public async resetPasswordRequest(email: string): Promise<void> {
    await this.getAxios().post('/session/password/request', {
      email,
      redirect: `${currentAddress()}/reset`,
    });
  }

  public async resetPassword(
    email: string,
    key: string,
    password: string,
  ): Promise<void> {
    await this.getAxios().post('/session/password/reset', {
      email,
      key,
      password,
    });
  }

  public async liliumToken(): Promise<string> {
    const { data } = await this.getAxios().get('/token/lilium');
    return data.data;
  }

  public async getPreferences(): Promise<any> {
    const { data } = await this.getAxios().get(`/preferences`);
    return data.data;
  }

  public async setPreferences(data: Record<string, unknown>): Promise<void> {
    await this.getAxios().put(`/preferences`, data);
  }

  public get hasPreloginToken(): boolean {
    return this.preLoginToken !== null;
  }

  public async cancelAccount(): Promise<void> {
    await this.getAxios().post('/subscription/cancel');
  }

  public async resumeAccount(): Promise<void> {
    await this.getAxios().delete('/subscription/cancel');
  }

  private async refreshJwt() {
    try {
      const { data } = await this.getAxios().post('/session/jwt/switch', {
        company: store.getters['auth/company'],
      });
      await store.dispatch('auth/set', data.data.token);
      this.localToken = this.activeToken;
      this.retrievingNewToken = false;
    } catch (e) {
      if ((e as any).response) {
        // Fancy typings here
        const response = (e as any).response as AxiosResponse<{
          error: { code: ApiErrorCode; message: string };
          data: { token: string };
        }>;

        if (
          response.status === 403 ||
          response.data.error.code === ApiErrorCode.LoginTokenExpired
        ) {
          await this.deauthenticate();
          window.location.href = '/login';
        }
      }
    }
  }

  private beforeRequestHandler(config: AxiosRequestConfig): AxiosRequestConfig {
    const state: AuthState = store.state.auth;
    // Haha, you're expired
    if (AuthGetters.expired(state) && !this.retrievingNewToken) {
      // Set retrieving so we don't spam deauthenticate
      this.retrievingNewToken = true;
      this.deauthenticate().then(() => {
        window.location.href = '/login';
      });

      throw new axios.Cancel('Login has expired');
    } else if (AuthGetters.nearlyExpired(state) && !this.retrievingNewToken) {
      // Kick off async fetch of new token
      this.retrievingNewToken = true;
      this.refreshJwt();
      // Don't cancel as we aren't expired yet :)
    }
    return config;
  }
}

export default new AuthService();
