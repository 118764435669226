import { BaseService } from './base';
import { CompanyExt, Office, User } from '@/models';
import { ThemeState } from '@/store/modules/theme/state';

class AccountantService extends BaseService {
  public async getCompanies(): Promise<CompanyExt[]> {
    const { data } = await this.axios().get(`/accountant/company`);
    return data.data.map(CompanyExt.deserialize);
  }

  public async updateCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/accountant/company/${c.id}`, c.serialize());
  }

  public async resetCompany(id: number): Promise<void> {
    await this.axios().put(`/accountant/company/${id}/reset`);
  }

  public async detachCompany(id: number): Promise<void> {
    await this.axios().delete(`/accountant/company/${id}`);
  }

  public async createCompany(c: CompanyExt): Promise<void> {
    await this.axios().post(`/accountant/company`, c.serialize());
  }

  public async getUsers(): Promise<User[]> {
    const { data } = await this.axios().get(`/accountant/user`);
    return data.data.map(User.deserialize);
  }

  public async createUser(u: User): Promise<void> {
    await this.axios().post(`/accountant/user`, u.serialize());
  }

  public async deleteUser(id: number): Promise<void> {
    await this.axios().delete(`/accountant/user/${id}`);
  }

  public async getOffices(): Promise<Office[]> {
    const { data } = await this.axios().get(
      `/accountant/integrations/officelist`,
    );
    return Object.entries<string>(data.data)
      .map(([key, value]) => new Office(key, value))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getTheme(): Promise<ThemeState> {
    const {
      data: { data },
    } = await this.axios().get(`/accountant/settings/theme`);
    return new ThemeState().assign(data);
  }

  public async updateTheme(newTheme: any): Promise<void> {
    await this.axios().post(`/accountant/settings/theme`, newTheme);
  }
}
export default new AccountantService();
