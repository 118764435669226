import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { Currency } from '@/lib/currency';
import { toUpper } from 'lodash-es';

export class InvoiceReceiver {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('name') public name: string;

  @autoserializeAs('address') public address: string;
  @autoserializeAs('postalCode') public zipcode: string;
  @autoserializeAs('city') public city: string;
  @autoserializeAs('country') public country: string;

  @autoserializeAs('coc') public coc: string;

  @autoserializeAs('email') public email: string | null;
  @autoserializeAs('phone') public phone: string | null = null;

  @autoserializeAs('debtorNumber') public debtorNumber: string | null;
  @autoserializeAs('vatNumber') public vatNumber: string | null;

  @autoserializeAs('person') public person: boolean;

  @autoserializeAs('optionalfield_1') public optional1: string | null = null;
  @autoserializeAs('optionalfield_2') public optional2: string | null = null;

  public currencyOverride: Currency | null = null;
  public expirationOverride: number | null = null;
  public extraTextOverride: string | null = null;
  public extraTextBottomOverride: string | null = null;
  public languageOverride: string | null = null;

  static deserialize(json: Record<string, unknown>): InvoiceReceiver {
    return Deserialize(json, InvoiceReceiver);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceReceiver);
  }

  public static OnDeserialized(
    instance: InvoiceReceiver,
    json: Record<string, unknown>,
  ): void {
    void json; // void json to prevent 'unused parameter' warning
    instance.country = toUpper(instance.country);
  }
}
