import { BaseService } from './base';
import { Product } from '@/models';
import { Paginated } from '@/lib/types';

interface ProductSearchOptions {
  term?: string;
  order?: string;
  reverse?: boolean;
  limit?: number;
}

const defaultSearchOptions: ProductSearchOptions = {
  limit: 10,
  reverse: true,
};

class ProductService extends BaseService {
  async get(id: string): Promise<Product> {
    const { data } = await this.axios().get(`/product/${id}`);
    return Product.deserialize(data.data);
  }

  async search(
    page: number = 0,
    options: ProductSearchOptions = {},
  ): Promise<Paginated<Product>> {
    const { data } = await this.axios().get(`/product/search`, {
      params: {
        ...defaultSearchOptions,
        ...options,
        page,
      },
    });

    data.data.items = data.data.items.map(Product.deserialize);
    return data.data;
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/product/${id}`);
  }

  async create(product: Product): Promise<Product> {
    const { data } = await this.axios().post(`/product`, product.serialize());
    return Product.deserialize(data.data);
  }

  async update(product: Product): Promise<Product> {
    const { data } = await this.axios().put(
      `/product/${product.id}`,
      product.serialize(),
    );
    return Product.deserialize(data.data);
  }
}

export default new ProductService();
