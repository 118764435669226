import { BaseService } from './base';
import { User } from '@/models';

class UserService extends BaseService {
  async all(): Promise<User[]> {
    const { data } = await this.axios().get(`/settings/user`);
    return data.data.map(User.deserialize);
  }

  async get(id: number): Promise<User> {
    void id;
    throw new Error('Unimplemented: Server cannot handle request');
  }

  async create(user: User): Promise<User> {
    const { data } = await this.axios().post(
      `/settings/user`,
      user.serialize(),
    );
    return User.deserialize(data.data);
  }

  async update(user: User): Promise<User> {
    const { data } = await this.axios().put(
      `/settings/user/${user.id}`,
      user.serialize(),
    );
    return User.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/user/${id}`);
  }
}

export default new UserService();
