import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { format, parse } from 'date-fns';
import { DATE_FORMAT_ISO } from '@/lib/constants';

export enum InvoiceRecurringFrequence {
  DAILY = 'd',
  WEEKLY = 'w',
  MONTHLY = 'm',
  YEARLY = 'y',
  LASTDAYMONTHY = 'LAST',
}

const fullnameMap = {
  d: 'Day',
  w: 'Week',
  m: 'Month',
  y: 'Year',
};

const FREQUENCE_REGEX = /^(\d+)([dwmy]|LAST)$/;

export class InvoiceRecurring {
  /**
   * Start date of the recurrence cycle. Used to add offsets to specified by frequency.
   * Formatted as ISO date string
   */
  @autoserializeAs('start') public start: string;

  /**
   * Frequence of the recurrence.
   * In the format /^(\d+)([dwmy]|LAST)$/
   *
   * Where the number specifies the quantity of time of the unit to the right.
   * For the units see {InvoiceRecurringFrequence}
   *
   * @see InvoiceRecurringFrequence
   */
  @autoserializeAs('frequence') public frequence: string;

  /**
   * The amount of times the invoice should be sent.
   * The special value 0 indicates infinity.
   */
  @autoserializeAs('times') public times: number | 'infinite' = 'infinite';

  /**
   * The remaining number of times this invoice will be sent
   */
  @autoserializeAs('remaining') public remaining: number;

  constructor() {
    this.startDate = new Date();
  }

  get startDate(): Date {
    return parse(this.start);
  }

  set startDate(value: Date) {
    this.start = format(value, DATE_FORMAT_ISO);
  }

  setFrequence(freq: number, delay: InvoiceRecurringFrequence): void {
    this.frequence = `${freq}${delay}`;
  }

  get frequencePart(): [number, InvoiceRecurringFrequence] {
    const result = FREQUENCE_REGEX.exec(this.frequence);
    if (!result) {
      throw new Error('Invalid frequency');
    }

    return [+result[1], result[2] as InvoiceRecurringFrequence];
  }

  get frequenceHuman(): string {
    const [num, mod] = this.frequencePart;
    if (mod === InvoiceRecurringFrequence.LASTDAYMONTHY) {
      return 'Month Last';
    }

    return `${num} ${fullnameMap[mod]}${num > 1 ? 's' : ''}`;
  }

  get timesHuman(): string {
    if (this.times === 'infinite') return '∞';
    return '' + this.times;
  }

  get remainingHuman(): string {
    if (this.remaining < 0) {
      this.remaining = 0;
    }
    return '' + this.remaining + '/' + this.timesHuman;
  }

  public static OnSerialized(
    instance: InvoiceRecurring,
    json: Record<string, unknown>,
  ): void {
    json.start = format(instance.start, DATE_FORMAT_ISO);
    if ((instance.times as string) === '') {
      json.times = 'infinite';
    }
  }

  static deserialize(json: Record<string, unknown>): InvoiceRecurring {
    return Deserialize(json, InvoiceRecurring);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceRecurring);
  }
}
