import { Module, Plugin } from 'vuex';
import { SettingState } from './state';
import Mutations from './mutations';
import Actions from './actions';
import Getters from './getters';

export class Settings implements Module<SettingState, any> {
  namespaced: boolean = true;

  state = new SettingState();
  mutations = Mutations;
  actions = Actions;
  getters = Getters;

  plugins: Plugin<SettingState>[];

  // create everything
  constructor(plugins: Plugin<SettingState>[] = []) {
    this.plugins = plugins;
  }
}
