export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  JPY = 'JPY',
  GBP = 'GBP',
  RUB = 'RUB',
  AUD = 'AUD',
  CHF = 'CHF',
  CAD = 'CAD',
  CNY = 'CNY',
  NOK = 'NOK',
  SEK = 'SEK',
  DKK = 'DKK',
}
