import { BaseService } from './base';
import { Integration } from '@/models';

interface ConnectResult {
  done: boolean;
  redirect?: string;

  popup: boolean;

  openConfig?: boolean;
}

export class IntegrationsService extends BaseService {
  private readonly prefix: string;
  private readonly isAccountant: boolean;

  constructor(accountant: boolean = false) {
    super();
    this.isAccountant = accountant;

    if (accountant) {
      this.prefix = '/accountant';
    } else {
      this.prefix = '/settings';
    }
  }

  async all(
    onlyEnabled: boolean = false,
    integrationClass?: string,
  ): Promise<Integration[]> {
    let query = '';
    if (integrationClass) {
      query = '?class=' + integrationClass;
    }
    const { data } = await this.axios().get(
      `${this.prefix}/integrations${query}`,
    );
    const integrations = data.data.map(
      Integration.deserialize,
    ) as Integration[];

    if (onlyEnabled) {
      return integrations.filter((e) => e.enabled);
    }
    return integrations;
  }

  async check(): Promise<Integration> {
    const { data } = await this.axios().get(
      `${this.prefix}/integrations/check`,
    );
    return Integration.deserialize(data.data);
  }

  async get(name: string, company?: number): Promise<Integration> {
    let query = '';
    if (this.isAccountant && company) {
      query += `company=${company}`;
    }

    const { data } = await this.axios().get(
      `${this.prefix}/integrations/${name}?${query}`,
    );
    return Integration.deserialize(data.data);
  }

  async connect(
    name: string,
    body: Record<string, unknown> = {},
  ): Promise<ConnectResult> {
    const { data } = await this.axios().post(
      `${this.prefix}/integrations/${name}`,
      body,
    );
    return data.data;
  }

  async update(
    name: string,
    body: Record<string, unknown> = {},
    company?: number,
  ): Promise<void> {
    let query = '';
    if (this.isAccountant && company) {
      query += `company=${company}`;
    }

    await this.axios().put(
      `${this.prefix}/integrations/${name}?${query}`,
      body,
    );
  }

  async delete(name: string): Promise<void> {
    await this.axios().delete(`${this.prefix}/integrations/${name}`);
  }

  async test(name: string): Promise<boolean> {
    try {
      const { data } = await this.axios().get(
        `${this.prefix}/integrations/${name}/test`,
      );
      return !!data.data.ok;
    } catch (e) {
      return false;
    }
  }

  async callback(
    provider: string,
    options: Record<string, unknown>,
  ): Promise<void> {
    await this.axios().get(`${this.prefix}/integrations/callback/${provider}`, {
      params: options,
    });
  }
}

export default new IntegrationsService();
