import { BaseService } from './base';
import { Profile } from '@/models';
import {
  ProfileEmailSettings,
  ProfileInvoiceSettings,
  ProfileSmsSettings,
} from '@/components/profile';

class ProfileService extends BaseService {
  async all(): Promise<Profile[]> {
    const { data } = await this.axios().get(`/settings/profile`);
    return data.data.map(Profile.deserialize);
  }

  async get(id: number): Promise<Profile> {
    const { data } = await this.axios().get(`/settings/profile/${id}`);
    return Profile.deserialize(data.data);
  }

  async getDefault(): Promise<Profile> {
    const { data } = await this.axios().get(`/settings/profile/default`);
    return Profile.deserialize(data.data);
  }

  async update(
    id: number,
    fields:
      | ProfileSmsSettings
      | ProfileInvoiceSettings
      | ProfileEmailSettings
      | Record<string, unknown>,
  ): Promise<Profile> {
    const { data } = await this.axios().put(`/settings/profile/${id}`, fields);
    return Profile.deserialize(data.data);
  }

  async delete(id: number): Promise<void> {
    await this.axios().delete(`/settings/profile/${id}`);
  }

  async default(id: number): Promise<void> {
    await this.axios().post(`/settings/profile/${id}/default`);
  }

  async new(): Promise<number> {
    const { data } = await this.axios().post(`/settings/profile`);
    return data.data;
  }

  async getLogo(logourl: string): Promise<any> {
    const { data } = await this.axios().get(`/settings/profile/${logourl}`);
    return data.data;
  }
}

export default new ProfileService();
