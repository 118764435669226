import {
  autoserializeAs,
  Deserialize,
  inheritSerialization,
  Serialize,
} from 'cerialize';
import { Accountant } from '@/models/Accountant';
import { Language } from '@/plugins/i18n';

@inheritSerialization(Accountant)
export class AccountantExt extends Accountant {
  @autoserializeAs('name')
  public userName: string;
  @autoserializeAs('email')
  private userEmail: string;

  @autoserializeAs('password')
  public userPassword: string;
  public userPasswordConfirm;

  @autoserializeAs('language')
  public language: string = Language.EN;

  static deserialize(json: Record<string, unknown>): AccountantExt {
    return Deserialize(json, AccountantExt);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, AccountantExt);
  }

  public static OnSerialized(
    accountant: AccountantExt,
    json: Record<string, unknown>,
  ): void {
    json['accountant_name'] = accountant.name;
  }
}
