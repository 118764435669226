import { BaseService } from './base';

export interface InvoiceNumbers {
  invoice: string;
  quotation: string;
}

class InvoiceNumbersService extends BaseService {
  async get(): Promise<InvoiceNumbers> {
    const { data } = await this.axios().get(`/settings/numbers`);
    return data.data as InvoiceNumbers;
  }

  async put(numbers: InvoiceNumbers): Promise<void> {
    await this.axios().put(`/settings/numbers`, numbers);
  }
}

export default new InvoiceNumbersService();
