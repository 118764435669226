import { GetterTree } from 'vuex';
import { SettingState } from './state';
import { SubscriptionStatus } from '@/store/modules/auth/state';

/**
 * Does the user have access to analytics?
 *
 * @param state
 */
export function hasAnalytics(state: SettingState): boolean {
  return state.subscription.package === 'premium';
}

/**
 * Did the user subscribe to be able to send SMS?
 *
 * @param state
 */
export function hasSms(state: SettingState): boolean {
  return state.subscription.sms_enabled;
}

/**
 * Has the user started the cancellation of his account?
 *
 * @param state
 */
export function hasCancelled(state: SettingState): boolean {
  return state.subscription.state === SubscriptionStatus.Cancelled;
}

/**
 * Is the user in archive-only mode?
 * Generally only happens through cancelling your account and having no grace left.
 *
 * @param state
 */
export function isViewOnly(state: SettingState): boolean {
  return (
    state.subscription.state === SubscriptionStatus.Pending ||
    state.subscription.state === SubscriptionStatus.Cancelled ||
    state.subscription.state === SubscriptionStatus.Suspended
  );
}

/**
 * Checks if the user still has grace days remaining for either confirmation or cancellation.
 *
 * @param state
 */
export function hasGrace(state: SettingState): boolean {
  return state.subscription.grace > 0;
}

/**
 * Gets the actual name for the currently logged in user
 *
 * @param state
 */
export function userName(state: SettingState): string {
  return state.user_data.name;
}

/**
 * Checks if user has
 *
 * @param state
 */
export function hasBookkeeping(state: SettingState): boolean {
  return state.subscription.bookkeeping_enabled;
}

export default {
  hasAnalytics,
  userName,
  hasCancelled,
  isViewOnly,
  hasGrace,
  hasSms,
  hasBookkeeping,
} as GetterTree<SettingState, any>;
