import Vue from 'vue';
import * as Sentry from '@sentry/vue';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import toaster from '@/plugins/toaster';
import { TurnstileObject } from 'turnstile-types';

import '@/polyfill';
import '@/styles/main.scss';

import {
  DEFAULT_I18N as i18n,
  setLanguageFromNavigator,
  loadLanguageAsync,
} from '@/plugins/i18n';
import '@/plugins/matomo';
import '@/plugins/serviceworker';
import '@/component-hooks';

Vue.config.productionTip = false;
Vue.use(toaster);

Sentry.init({
  Vue,
  // for now the DSN is hardcoded but it should be moved into a config file
  dsn: 'https://263c0d085acd4302aa5227f34e7859da@o4504684804308992.ingest.sentry.io/4504684805750784',
});

// We have to declare turnstile variable in order to please TS linter
declare global {
  interface Window {
    turnstile: TurnstileObject;
  }
}

window['setLanguage'] = loadLanguageAsync;
setLanguageFromNavigator().then(() => {
  new Vue({
    i18n,
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
});

window['TNI_VERSION'] = process.env.VUE_APP_VERSION;
