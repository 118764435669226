import Lilium from './lilium';
import TnfConfig from '@/config';
import { unwrapError } from '@/lib/helpers';
import { Toaster } from '@/toaster';

let lilium: Lilium | null = null;
let toaster: Toaster | null = null;

export default class NotificationsManager {
  public static connect(token: string): void {
    if (lilium) {
      NotificationsManager.disconnect();
    }
    lilium = new Lilium(TnfConfig.notifications.base, token);
    lilium.register('notify', NotificationsManager.notify);
  }

  public static disconnect(): void {
    if (!lilium) {
      return;
    }

    lilium.close();
    lilium = null;
  }

  public static onFailed(e: Error): void {
    if (toaster) {
      toaster.error('Could not connect to notifications', unwrapError(e));
    }
  }

  public static notify(notification: Record<string, any>): void {
    if (!toaster) {
      return;
    }

    const message = notification.message;
    const title =
      notification.data && notification.data.title
        ? notification.data.title
        : '';
    const priority =
      notification.data && notification.data.priority
        ? notification.data.priority
        : 'info';

    toaster.add(title, message, priority, 5000);
  }

  public static setToaster(t: Toaster): void {
    toaster = t;
  }

  public static getToaster(): Toaster | null {
    return toaster;
  }
}
