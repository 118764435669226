import { format } from 'date-fns';
import { CoronaData } from '@/lib/corona/index';
import { LegalForm, TaxReturnFrequency } from '@/lib/corona/costs';

export const SALARY_FACTOR = 0.7;
export const VAT_HIGH_FACTOR = 1.21;

export function getLiquidityColumn(
  data: CoronaData,
  yearIndex: number,
  month: number,
  monthIndex: number,
  bankBalance: number,
): LiquidityColumn {
  // NOTE: the index here is the actual position in the revenue matrix.
  // The month is the actual month that this column represents
  const exploitationMatrix = data.exploitationMatrix(yearIndex);

  const exploitationColumn = exploitationMatrix.getMonth(monthIndex);

  const actualYear = data.yearForIndex(yearIndex, monthIndex);
  const date = new Date(actualYear, month, 1);

  const bankLoan =
    monthIndex === 0 && yearIndex === 0 ? data.costs.loanAmount : 0;

  const turnover =
    exploitationColumn.turnover +
    exploitationColumn.turnover * data.costs.avgVATFactor;

  const salary = exploitationColumn.salary * SALARY_FACTOR;
  // why yes, I am to be summarily shot after this project, don't worry.
  const previous_salary = getPreviousSalary(data, yearIndex, monthIndex);

  const stockCost =
    exploitationColumn.stockValue +
    exploitationColumn.stockValue * data.costs.avgVATFactor;

  const rent = exploitationColumn.rent * VAT_HIGH_FACTOR;

  const exploitation = exploitationColumn.exploitation * VAT_HIGH_FACTOR;
  const sales = exploitationColumn.sales * VAT_HIGH_FACTOR;
  const officeCosts = exploitationColumn.officeCosts * VAT_HIGH_FACTOR;
  const rest = exploitationColumn.restCosts * VAT_HIGH_FACTOR;

  const annuity = data.costs.loanAnnuity;

  const privateWithdrawal =
    data.costs.legalForm === LegalForm.EZ_VOF
      ? data.costs.privateWithdrawal
      : -1;

  // the income tax is computed over the previous months salary
  const incomeTax =
    monthIndex === 0 && yearIndex === 0
      ? data.costs.dueIncomeTax
      : (previous_salary / 7) * 3;

  let revenueTax = getRevenueTax(
    data,
    yearIndex,
    monthIndex,
    month,
    data.costs.avgVATFactor,
    data.costs.taxReturnFrequency,
  );

  // DUE INCOME TAX SPECIAL CASES
  if (
    data.costs.taxReturnFrequency === TaxReturnFrequency.Quarterly &&
    month % 3 === 0 &&
    monthIndex <= 3 &&
    yearIndex === 0
  ) {
    // we're in the special case of quarterly tax return + first quarter of the first year of the sheet
    revenueTax += data.costs.dueRevenueTax;
  } else if (
    data.costs.taxReturnFrequency === TaxReturnFrequency.Monthly &&
    monthIndex === 0 &&
    yearIndex === 0
  ) {
    // monthly tax return + first month of the year
    revenueTax += data.costs.dueRevenueTax;
  }

  const creditors = data.debtForDate(date);

  return new LiquidityColumn(
    date,
    data.costs.legalForm,
    bankLoan,
    turnover,
    stockCost,
    salary,
    rent,
    exploitation,
    sales,
    officeCosts,
    rest,
    annuity,
    incomeTax,
    revenueTax,
    bankBalance,
    creditors,
    privateWithdrawal,
    data.costs.managementFee,
  );
}

export function getPreviousSalary(
  data: CoronaData,
  yearIndex: number,
  monthIndex: number,
): number {
  const first_year_matrix = data.exploitationMatrix(0);

  // if we're completely at the start, we can't compute prior salary
  if (monthIndex === 0 && yearIndex === 0) {
    return 0;
  }
  // If we're in the first year and we're not taking the first month, compute from first year matrix
  if (monthIndex !== 0 && yearIndex === 0) {
    return first_year_matrix.getMonth(monthIndex - 1).salary;
  }
  // First month of new year is special, needs the salary of last year.
  if (monthIndex === 0 && yearIndex === 1) {
    return first_year_matrix.getMonth(11).salary;
  }

  const second_year_matrix = data.exploitationMatrix(1);
  return second_year_matrix.getMonth(monthIndex - 1).salary;
}

export function getRevenueTax(
  data: CoronaData,
  yearIndex: number,
  monthIndex: number,
  month: number,
  vatFactor: number,
  taxReturnFrequency: TaxReturnFrequency,
): number {
  // first, early return for quarterly shenanigans
  if (taxReturnFrequency === TaxReturnFrequency.Quarterly && month % 3 !== 0) {
    return 0;
  }

  const matrix_first_year = data.exploitationMatrix(0);

  // Irrespective of tax return frequency, the first year is relatively normal.
  if (yearIndex === 0) {
    return matrix_first_year.getRevenueTax(
      monthIndex,
      month,
      vatFactor,
      taxReturnFrequency,
    );
  }

  const matrix_second_year = data.exploitationMatrix(1);

  // Special case for monthly tax return: first month index of the next year.
  // needs revenue tax of previous month, which is the last index of previous year
  if (
    taxReturnFrequency === TaxReturnFrequency.Monthly &&
    monthIndex === 0 &&
    yearIndex === 1
  ) {
    return matrix_second_year.getRevenueTax(
      11,
      data.monthForIndex(11),
      vatFactor,
      taxReturnFrequency,
    );
  }
  // rest of monthly payments are simple.
  if (taxReturnFrequency === TaxReturnFrequency.Monthly && yearIndex === 1) {
    return matrix_second_year.getRevenueTax(
      monthIndex,
      month,
      vatFactor,
      taxReturnFrequency,
    );
  }

  // Next up, quarterly computations
  // here we have to watch for potential overlap between columns:
  // next year index, and index 0, 1, 2 need revenue tax of previous years: they need 3, 2 or 1 month from the previous
  // year, respectively.
  if (yearIndex === 1) {
    // First month of the new year needs the last three of the previous year
    if (monthIndex === 0) {
      let totalRevenueTax = 0;
      totalRevenueTax += matrix_first_year
        .getMonth(9)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_first_year
        .getMonth(10)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_first_year
        .getMonth(11)
        .computeRevenueTax(vatFactor);
      return totalRevenueTax;
    } else if (monthIndex === 1) {
      // second month of the new year needs the last two of the previous year
      let totalRevenueTax = 0;
      totalRevenueTax += matrix_first_year
        .getMonth(10)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_first_year
        .getMonth(11)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_second_year
        .getMonth(0)
        .computeRevenueTax(vatFactor);
      return totalRevenueTax;
    } else if (monthIndex === 1) {
      // second month of the new year needs the last two of the previous year
      let totalRevenueTax = 0;
      totalRevenueTax += matrix_first_year
        .getMonth(11)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_second_year
        .getMonth(0)
        .computeRevenueTax(vatFactor);
      totalRevenueTax += matrix_second_year
        .getMonth(1)
        .computeRevenueTax(vatFactor);
      return totalRevenueTax;
    } else {
      return matrix_second_year.getRevenueTax(
        monthIndex,
        month,
        vatFactor,
        taxReturnFrequency,
      );
    }
  }
  // Wait how the fuck did we end up here???
  return -1337;
}

export class LiquidityColumn {
  month: Date;
  legalForm: LegalForm;
  startingBankBalance: number;
  turnover: number;
  bankLoan: number;
  stockValue: number;
  salary: number;
  rent: number;
  exploitation: number;
  sales: number;
  officeCosts: number;
  restCosts: number;
  annuity: number;
  incomeTax: number;
  revenueTax: number;
  creditors: number;
  privateWithdrawal: number;
  managementFee: number;

  constructor(
    month: Date,
    legalForm: LegalForm,
    bankLoan: number,
    turnover: number,
    stockValue: number,
    salary: number,
    rent: number,
    exploitation: number,
    sales: number,
    officeCosts: number,
    restCosts: number,
    annuity: number,
    incomeTax: number,
    revenueTax: number,
    startingBankBalance: number,
    creditors: number,
    privateWithdrawal: number,
    managementFee: number,
  ) {
    this.month = month;
    this.legalForm = legalForm;
    this.bankLoan = bankLoan;
    this.turnover = turnover;
    this.stockValue = stockValue;
    this.salary = salary;
    this.rent = rent;
    this.exploitation = exploitation;
    this.sales = sales;
    this.officeCosts = officeCosts;
    this.restCosts = restCosts;
    // TODO: annuity might be negative, but we need it to be positive
    // for computation and display purposes.
    this.annuity = Math.abs(annuity);
    this.incomeTax = incomeTax;
    this.revenueTax = revenueTax;
    this.startingBankBalance = startingBankBalance;
    this.creditors = creditors;
    this.privateWithdrawal = privateWithdrawal;
    this.managementFee = managementFee;
  }

  get totalExpenses(): number {
    let expenses =
      this.stockValue +
      this.salary +
      this.rent +
      this.exploitation +
      this.sales +
      this.officeCosts +
      this.restCosts +
      this.annuity +
      this.incomeTax +
      this.revenueTax +
      this.creditors;

    if (this.legalForm === LegalForm.EZ_VOF) {
      expenses += this.privateWithdrawal;
    } else {
      expenses += this.managementFee;
    }

    return expenses;
  }

  get totalIncome(): number {
    return this.turnover + this.bankLoan;
  }

  get closingBankBalance(): number {
    return this.startingBankBalance + this.totalIncome - this.totalExpenses;
  }

  get humanMonth(): string {
    return format(this.month, 'MMMM');
  }
}
