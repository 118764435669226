import { RouteConfig } from 'vue-router';
import { page } from './helper';

const WRAPPER = (): any => page(import('@/views/Wrapper.vue'));

export const routes: RouteConfig[] = [
  { path: '', name: 'settings', redirect: 'general' },
  {
    path: 'general',
    name: 'settings-general',
    component: () => page(import('@/views/settings/General.vue')),
  },
  {
    path: 'profile',
    component: WRAPPER,
    children: [
      {
        path: '',
        name: 'settings-profile',
        component: () => page(import('@/views/settings/Profile.vue')),
      },
      {
        path: 'edit/:profileId',
        name: 'settings-profile-edit',
        component: () => page(import('@/views/settings/ProfileEditor.vue')),
      },
    ],
  },
  {
    path: 'products',
    name: 'settings-products',
    component: () => page(import('@/views/settings/Products.vue')),
  },
  {
    path: 'email',
    component: WRAPPER,
    children: [
      {
        path: '',
        name: 'settings-email',
        component: () => page(import('@/views/settings/Email.vue')),
      },
      {
        path: 'message/:messageId',
        name: 'settings-emailtext-edit',
        component: () =>
          page(import('@/views/settings/EmailMessageEditor.vue')),
      },
    ],
  },
  {
    path: 'sms',
    component: WRAPPER,
    children: [
      {
        path: '',
        name: 'settings-sms',
        component: () => page(import('@/views/settings/Sms.vue')),
      },
      {
        path: 'text/:id',
        name: 'settings-smstext-edit',
        component: () => page(import('@/views/settings/SmsTextEdit.vue')),
      },
    ],
  },
  {
    path: 'integrations',
    name: 'settings-integrations',
    component: () => page(import('@/views/settings/Integrations.vue')),
  },
  {
    path: 'payment',
    name: 'settings-payment',
    component: () => page(import('@/views/settings/Payments.vue')),
  },
  {
    path: 'users',
    name: 'settings-users',
    component: () => page(import('@/views/settings/Users.vue')),
  },
  {
    path: 'account',
    name: 'settings-account',
    component: () => page(import('@/views/settings/Account.vue')),
  },
];

export default routes;
