import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import router from '@/router';
import TnfConfig from '@/config';

try {
  if (TnfConfig.matomo.base && TnfConfig.matomo.site) {
    Vue.use(VueMatomo, {
      host: TnfConfig.matomo.base,
      siteId: TnfConfig.matomo.site,
      trackInitialView: false,
      router,
    });
  }
} catch (e) {
  // skip
}
