import { Route } from 'vue-router';

/**
 * Work around the bullshit of ES modules and webpack
 * @param imp import('pagepath')
 * @return fixed import
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function page(imp: any): Promise<any> {
  return imp.then((mod) => mod.default || mod);
}

function routeHasAccessProperty(route: Route, property: string): boolean {
  if (route.meta?.hasOwnProperty(property)) {
    return route.meta[property];
  }

  return !!route.matched.find((e) => e.meta[property]);
}

export function canAccessWithoutAuth(to: Route): boolean {
  return routeHasAccessProperty(to, 'noAuthRequired');
}

export function isAdminRoute(to: Route): boolean {
  return routeHasAccessProperty(to, 'adminRequired');
}

export function isAccountantRoute(to: Route): boolean {
  return routeHasAccessProperty(to, 'accountantRequired');
}

export function canAccessWhileCancelled(to: Route): boolean {
  return routeHasAccessProperty(to, 'allowCancelled');
}
