import { BaseService } from './base';

class ImageService extends BaseService {
  async uploadInline(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('image', file);

    const { data } = await this.axios().post(`/inline-image`, formData);
    return data.data.url;
  }

  async uploadWhitelabelLogo(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('image', file);
    const {
      data: { data },
    } = await this.axios().post(`/whitelabel-logo`, formData);
    return data.url;
  }
}

export default new ImageService();
