import { MutationTree } from 'vuex';
import { SettingState } from './state';

function set(state: SettingState, newState: SettingState): void {
  Object.assign(state, newState);
}

function setSmsEnabled(state: SettingState, enabled: boolean): void {
  state.subscription.sms_enabled = enabled;
}

function clear(state: SettingState): void {
  Object.assign(state, new SettingState());
}

export default {
  set,
  setSmsEnabled,
  clear,
} as MutationTree<SettingState>;
