






















































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  ActionButton,
  ActionRouterLink,
  ActionButtonList,
  ActionHrefLink,
} from './actionbuttons';
import Popover from './Popover.vue';
import SearchBar from './SearchBar.vue';
import Logo from '@/components/Logo.vue';

import { AuthService } from '@/lib/services';
import { ThemeColors, THEME_DARK, THEME_LIGHT } from '@/lib/themes';
import { isEqual } from 'lodash';

const sSettings = namespace('settings');
const sAuth = namespace('auth');
const sTheme = namespace('theme');

@Component({
  components: {
    Logo,
    ActionButton,
    ActionRouterLink,
    ActionButtonList,
    ActionHrefLink,
    Popover,
    SearchBar,
  },
})
export default class Navbar extends Vue {
  showMenu: boolean = false;
  @sSettings.Getter('hasAnalytics') hasAnalytics: boolean;
  @sSettings.Getter('isViewOnly') viewOnly: boolean;
  @sAuth.State((s) => s.data.is_accountant) isAccountant: boolean;
  @sAuth.Getter('isAdmin') isAdmin: boolean;

  @sTheme.Getter('colors') colorScheme: ThemeColors;
  @sTheme.Action('setColorScheme') setColorScheme: (arg: ThemeColors) => void;

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  @Watch('$route')
  closeMenu(): void {
    this.showMenu = false;
  }

  logout(): void {
    AuthService.logout();
  }

  setLightTheme(): void {
    this.setColorScheme(THEME_LIGHT);
  }

  setDarkTheme(): void {
    this.setColorScheme(THEME_DARK);
  }

  get isLightTheme(): boolean {
    return isEqual(this.colorScheme, THEME_LIGHT);
  }

  get isDarkTheme(): boolean {
    return isEqual(this.colorScheme, THEME_DARK);
  }
}
