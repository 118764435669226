import { Language } from '@/plugins/i18n';

export class SettingState {
  company_name: string;
  language: string = Language.EN;
  country: string = 'NL';
  theme: any;

  user_data: {
    name: string;
    email: string;
  } = {
    name: '',
    email: '',
  };

  subscription: {
    addons: string[];
    bookkeeping_enabled: boolean;
    state: string;
    grace: number;
    satisfied: boolean;
    package: string;
    under_accountant: boolean;
    sms_enabled: boolean;
  } = {
    addons: [],
    bookkeeping_enabled: false,
    state: 'unknown',
    grace: 0,
    satisfied: false,
    package: 'unknown',
    under_accountant: false,
    sms_enabled: false,
  };

  /**
   * @deprecated Use subscription.addons instead
   */
  addons: string[] = [];
  /**
   * @deprecated Use subscription.grace instead
   */
  grace: number = 0;
  /**
   * @deprecated Use subscription.package instead
   */
  package: string = '';
}
