import { BaseService } from './base';
import { Invoice } from '@/models';

export interface ExternalInvoiceInfo {
  // Its the unserialized, but with calculated one
  invoice: Invoice;
  has_tac: boolean;
  paid: boolean;
  pay_options: string[];
  message: string;
  success_message: string;
}

class ExternalInvoiceService extends BaseService {
  async get(shortId: string): Promise<ExternalInvoiceInfo> {
    const { data } = await this.axios().get(`/external/invoice/${shortId}`);
    return data.data;
  }

  async logo(shortId: string): Promise<string> {
    const { data } = await this.axios().get(
      `/external/invoice/${shortId}/logo`,
    );
    return data.data;
  }

  async view(shortId: string): Promise<Blob> {
    const { data } = await this.axios().get(
      `/external/invoice/${shortId}/view`,
      {
        responseType: 'blob',
      },
    );
    return data;
  }

  async pay(shortId: string, method: string): Promise<string> {
    const { data } = await this.axios().post(
      `/external/invoice/${shortId}/pay/${method}`,
    );
    return data.data;
  }
}

export default new ExternalInvoiceService();
