import Vue from 'vue';
import Router from 'vue-router';
import { AuthService } from '@/lib/services';
import store from '@/store';

import NotFound from '@/views/NotFound.vue';

import SettingsRoutes from './settings';
import ExternalRoutes from './external';
import AccountantRoutes from './accountant';
import AdminRoutes from './admin';
import { Language, loadLanguageAsync } from '@/plugins/i18n';
import {
  canAccessWhileCancelled,
  canAccessWithoutAuth,
  isAccountantRoute,
  isAdminRoute,
  page,
} from './helper';
import { AclRole } from '@/store/modules/auth/state';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', name: 'home', redirect: '/dashboard' },
    {
      path: '/login/2fa',
      name: 'login-2fa',
      component: () => page(import('@/views/login/TwoFactor.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => page(import('@/views/login/Login.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => page(import('@/views/login/Register.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/recover',
      name: 'recover',
      component: () => page(import('@/views/login/Recovery.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => page(import('@/views/login/Reset.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/verify',
      name: 'emailverify',
      component: () => page(import('@/views/EmailVerify.vue')),
      meta: {
        noAuthRequired: true,
        graceful: true,
      },
    },
    {
      path: '/confirm-subscription',
      name: 'confirm-subscription',
      component: () => page(import('@/views/ConfirmSubscription.vue')),
      meta: {
        graceful: true,
        allowCancelled: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => page(import('@/views/Dashboard.vue')),
    },
    {
      path: '/setup',
      name: 'setup',
      component: () => page(import('@/views/Setup.vue')),
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => page(import('@/views/Archive.vue')),
      meta: {
        allowCancelled: true,
      },
    },
    {
      path: '/invoices',
      name: 'invoice_create',
      component: () => page(import('@/views/InvoiceCreate.vue')),
    },
    {
      path: '/customers/import',
      name: 'customers_import',
      component: () => page(import('@/views/CustomersImport.vue')),
    },
    {
      path: '/customers/merge',
      name: 'customers_merge',
      component: () => page(import('@/views/CustomersMerge.vue')),
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => page(import('@/views/Customers.vue')),
    },
    {
      path: '/switch-company',
      name: 'switch-company',
      component: () => page(import('@/views/CompanySwitch.vue')),
      meta: {
        graceful: true,
        allowCancelled: true,
      },
    },
    // This route will not use the settings router as I dont want it to have the frames around it
    {
      path: '/settings/integrations/callback/:provider',
      name: 'settings-integration-callback',
      component: () => page(import('@/views/settings/IntegrationCallback.vue')),
    },
    {
      path: '/settings',
      component: () => page(import('@/views/Settings.vue')),
      children: SettingsRoutes,
    },
    {
      path: '/analytics',
      component: () => page(import('@/views/Analytics.vue')),
    },
    {
      path: '/notifications',
      component: () => page(import('@/views/Notifications.vue')),
    },
    {
      path: '/accountant',
      component: () => page(import('@/views/Accountant.vue')),
      children: AccountantRoutes,
      meta: {
        accountantRequired: true,
      },
    },
    {
      path: '/prognose',
      component: () => page(import('@/views/Corona.vue')),
    },
    {
      path: '/admin',
      component: () => page(import('@/views/Admin.vue')),
      children: AdminRoutes,
      meta: {
        adminRequired: true,
      },
    },
    {
      path: '/external',
      component: () => page(import('@/views/Wrapper.vue')),
      children: ExternalRoutes,
      meta: {
        noAuthRequired: true,
      },
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound,
      meta: {
        noAuthRequired: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (lang) {
    loadLanguageAsync(lang as Language).then(() => next());
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (AuthService.isAuthenticated) {
    // isInitialized guards against pre-restore-auth routing errors.
    // If the pre-auth detects wrong it'll route the user regardless
    if (
      AuthService.isInitialized &&
      !store.state.settings.subscription.satisfied &&
      store.state.settings.subscription.grace <= 0 &&
      !to.meta?.graceful &&
      !canAccessWhileCancelled(to)
    ) {
      next({ name: 'confirm-subscription' });
    } else if (
      isAccountantRoute(to) &&
      !(
        store.state.auth.data.is_accountant ||
        store.state.auth.data.is_accountant
      )
    ) {
      next('/dashboard');
    } else if (
      isAdminRoute(to) &&
      store.state.auth.data.role !== AclRole.Admin
    ) {
      next('/dashboard');
    } else if (
      store.getters['settings/isViewOnly'] &&
      !canAccessWhileCancelled(to)
    ) {
      next('/archive');
    } else {
      next();
    }
  } else if (canAccessWithoutAuth(to)) {
    next();
  } else {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  }
});

export default router;
