import { RouteConfig } from 'vue-router';
import { page } from './helper';

export const routes: RouteConfig[] = [
  {
    path: 'lightspeed',
    name: 'external-lightspeed',
    component: () => page(import('@/views/external/Lightspeed.vue')),
  },
  {
    path: 'untill',
    name: 'external-untill',
    component: () => page(import('@/views/external/Untill.vue')),
  },
  {
    path: 'lightspeedretail',
    name: 'external-lightspeed-retail',
    component: () => page(import('@/views/external/LightspeedRetail.vue')),
    meta: {
      noAuthRequired: false,
    },
  },
  {
    path: 'lskseries',
    name: 'external-lightspeed-kseries',
    component: () => page(import('@/views/external/LightspeedKSeries.vue')),
  },
  {
    path: 'quotation/:link',
    name: 'external-quotation',
    component: () => page(import('@/views/external/Quotation.vue')),
  },
  {
    path: 'invoice/:id/view',
    name: 'external-invoice-view',
    component: () => page(import('@/views/external/InvoiceView.vue')),
  },
  {
    path: 'invoice/:id',
    name: 'external-invoice',
    component: () => page(import('@/views/external/Invoice.vue')),
  },
];

export default routes;
