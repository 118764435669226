



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BoundsCalculator } from '@/lib/boundingcalculations';

import { throttle } from 'lodash-es';

const popovers: Popover[] = [];
const supportedPositions = BoundsCalculator.supportedPositions();

@Component
export default class Popover extends Vue {
  @Prop() name: string;

  @Prop({ default: false, type: Boolean })
  hover: boolean;

  @Prop({ default: 'bottom', validator: (x) => supportedPositions.includes(x) })
  position: string;

  @Prop({ default: false, type: Boolean })
  ignoreClick: boolean;

  @Prop({ default: false, type: Boolean })
  acceptChildClick: boolean;

  isOpen: boolean = false;

  cssPosition = { left: '0px', top: '0px' };

  onMouseOver(): void {
    if (this.hover) this.open();
  }

  onMouseOut(): void {
    if (this.hover) this.close();
  }

  mounted(): void {
    popovers.push(this);
  }

  beforeDestroy(): void {
    popovers.slice(popovers.indexOf(this), 1);
  }

  toggle(e: Event): void {
    e.stopPropagation();
    if (this.ignoreClick) return;

    if (this.isOpen) {
      this.close();
      return;
    }

    popovers.forEach((popover) => popover.close());

    this.open();
  }

  onDocumentClick(e: Event): void {
    if (this.acceptChildClick) {
      let p: HTMLElement | null = e.target as HTMLElement;
      while (p) {
        if (p === this.$el) {
          return;
        }
        p = p.parentElement;
      }
    }
    this.close();
  }

  open(): void {
    this.isOpen = true;
    document.documentElement.addEventListener(
      'click',
      this.onDocumentClick,
      false,
    );
    document.addEventListener('scroll', this.throttleCalcPosition, {
      passive: true,
    });
    this.$emit('open');
    this.calcPosition();
  }

  calcPosition(): void {
    const root = this.$refs['root'] as HTMLElement;
    const child = this.$refs['container'] as HTMLElement;
    if (!root || !child) return;

    const pos = BoundsCalculator[this.position](
      root.getBoundingClientRect(),
      child.getBoundingClientRect(),
    );

    this.cssPosition = {
      left: Math.max(pos.x, 0) + 'px',
      top: pos.y + 'px',
    };
  }

  throttleCalcPosition = throttle(this.calcPosition, 20);

  close(): void {
    this.isOpen = false;
    document.documentElement.removeEventListener(
      'click',
      this.onDocumentClick,
      false,
    );
    document.removeEventListener('scroll', this.throttleCalcPosition, false);

    this.$emit('close');
  }

  get id(): string {
    return `popover-${this['_uid']}`;
  }
}
