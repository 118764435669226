import { addMonths, isWithinRange, parse, format } from 'date-fns';
import {
  DATE_FORMAT_ISO,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_OPTIONS,
} from '@/lib/constants';

export class CoronaDebt {
  holder: string;
  amount: number;
  // The amount of months to repay the debt over
  repaymentTerm: number;
  // The start date of the repayment, accurate to the month
  repaymentStart: Date;

  constructor(
    holder: string,
    amount: number,
    repaymentTerm: number,
    repaymentStart: Date,
  ) {
    this.holder = holder;
    this.amount = amount;
    this.repaymentTerm = repaymentTerm;
    this.repaymentStart = repaymentStart;
  }

  getPaymentForMonth(date: Date): number {
    return isWithinRange(date, this.repaymentStart, this.repaymentEnd)
      ? this.monthlyRepayment
      : 0;
  }

  formattedMonthlyRepayment(): string {
    return this.monthlyRepayment.toLocaleString(
      DEFAULT_LOCALE,
      DEFAULT_LOCALE_OPTIONS,
    );
  }

  set repaymentStartString(date: string) {
    this.repaymentStart = parse(date);
  }

  get repaymentStartString(): string {
    return format(this.repaymentStart, DATE_FORMAT_ISO);
  }

  get repaymentEnd(): Date {
    return addMonths(this.repaymentStart, this.repaymentTerm - 1);
  }

  get monthlyRepayment(): number {
    return this.amount / this.repaymentTerm;
  }
}
