import { autoserializeAs, Deserialize, Serialize } from 'cerialize';

import {
  InvoiceLine,
  InvoiceMeta,
  InvoiceReceiver,
  InvoiceSender,
  InvoiceText,
  InvoiceVAT,
} from '.';
import { uuidv4 } from '@/lib/uuid';
import Big from 'big.js';
import { BIG_ROUND_HALF_EVEN } from '@/lib/constants';

/**
 * InvoiceType represents the payment type (ish) of the invoice.
 */
export enum InvoiceType {
  // A standard invoice
  STANDARD = '',
  // A credit invoice
  CREDIT = 'C',
  // An invoice with automatic collection
  AUTOMATIC_COLLECTION = 'I',
}

/**
 * InvoiceStatus represents the current status of the invoice.
 */
export enum InvoiceStatus {
  // Draft represents an unsent invoice, without special meaning
  DRAFT = 0,
  // Sent means that the invoice has been sent to the receiver
  SENT = 1,
  // Recurring means that the invoice recurs on a fixed period
  RECURRING = 2,
  // Credited means that a credit invoice has been sent to the receiver
  CREDITED = 3,
  // Quotation means that this invoice is a quotation, and has been sent to the receiver
  QUOTATION = 4,
  // DraftQuotation means that this invoice is a quotation, but has not been sent to
  // the receiver (yet)
  DRAFT_QUOTATION = 5,
  // ArchivedQuotation means that the quotation has ben archived
  ARCHIVED_QUOTATION = 6,
}

/**
 * Invoice represents a single invoice in TNI
 */
export class Invoice {
  /**
   * Invoice ID uniquely identifies an invoice
   *
   * Currently a bit undefined (number), should become a UUID in the future
   */
  @autoserializeAs('id') public id: string;

  /**
   * Token specifically for uniqueness
   */
  @autoserializeAs('creationToken') public creationToken: string | null;

  /**
   * Invoice number
   */
  @autoserializeAs('number') public number: string;

  /**
   * Sender represents the party that sent the invoice.
   */
  @autoserializeAs('sender') public sender: InvoiceSender;

  /**
   * Receiver represents the party that receives the invoice.
   */
  @autoserializeAs('receiver')
  public receiver: InvoiceReceiver = new InvoiceReceiver();

  /**
   * Meta contains several metadata attributes related to this invoice.
   * Includes things as date sent, expiration time etc.
   */
  @autoserializeAs('meta') public meta: InvoiceMeta;

  /**
   * Various text objects to add to the invoice
   */
  @autoserializeAs('text') public text: InvoiceText;

  /**
   * Type represents the type of this invoice (Normal / Credit / etc)
   */
  @autoserializeAs('type') public type: InvoiceType = InvoiceType.STANDARD;

  /**
   * Lines contains the actual lines of this invoice.
   */
  @autoserializeAs('lines') public lines: InvoiceLine[] = [];

  /**
   * Status represents the current status of the invoice,
   * as well as some information about what type of invoice it is (normal or quotation)
   */
  @autoserializeAs('status') public status: InvoiceStatus = InvoiceStatus.DRAFT;

  /**
   * Discount over the invoice in the range (0 .. 100)
   */
  @autoserializeAs('discount') public discount: number = 0;

  /**
   * Does the invoice line totals include VAT?
   */
  @autoserializeAs('includesVat') public includesVat: boolean = false;

  /**
   * Calculated properties of the invoice
   *
   * Unused frontend-wise
   */
  @autoserializeAs('calculated') public calculated: any | null;

  constructor() {
    this.creationToken = uuidv4();
    this.meta = new InvoiceMeta();
    this.text = new InvoiceText();
  }

  get isQuotation(): boolean {
    return [
      InvoiceStatus.QUOTATION,
      InvoiceStatus.DRAFT_QUOTATION,
      InvoiceStatus.ARCHIVED_QUOTATION,
    ].includes(this.status);
  }

  get isRecurring(): boolean {
    return this.status === InvoiceStatus.RECURRING;
  }

  /**
   * totalExclVat is the computed total of all the invoice lines *without* VAT taken
   * into consideration
   *
   * @returns {number} Total amount over all invoice lines, excluding VAT
   */
  get totalExclVat(): Big {
    if (this.includesVat) {
      return this.totalInclVat.sub(this.totalVat);
    }
    return this.lines
      .reduce((s, e) => s.add(e.subTotalExclVat), new Big(0))
      .mul(this.discountFactor)
      .round(2, BIG_ROUND_HALF_EVEN);
  }

  /**
   * totalInclVat is the computed total of all the invoice lines *with* VAT taken
   * into consideration
   *
   * @returns {number} Total amount over all invoice lines, including VAT
   */
  get totalInclVat(): Big {
    if (this.includesVat) {
      return this.lines
        .reduce((s, e) => s.add(e.subTotalInclVat), new Big(0))
        .mul(this.discountFactor)
        .round(2, BIG_ROUND_HALF_EVEN);
    }

    return this.totalExclVat.add(this.totalVat);
  }

  /**
   * Return an object containing the total amount of VAT for each factor
   * in use on the invoice.
   * The total amounts are rounded to 2 decimal positions
   */
  get vatAmountsPerFactor(): { [p: number]: Big } {
    const vatMap = this.lines
      // Remove all lines that do not have VAT set, or have 0% VAT
      .filter((line) => line.vat != null && line.vat.factor > 0)
      // We will now continue to sum all VAT in buckets, where the bucket is the VAT factor
      // This will produce an object akin to {'1.09': Big(12.00), '1.21': Big(300)}
      .reduce((vats, line) => {
        const factor = line.vat!.factor;
        // Set the factor to zero if its not in the map yet
        if (!vats[factor]) {
          vats[factor] = new Big(0);
        }

        // Add the line vat to its factor's sum
        vats[factor] = vats[factor].add(line.vatAmount);
        return vats;
      }, {} as { [factor: number]: Big });

    // Round all factors' sums to 2 decimals
    for (const key in vatMap) {
      vatMap[key] = vatMap[key].round(2, BIG_ROUND_HALF_EVEN);
    }

    return vatMap;
  }

  get totalVat(): Big {
    // Calculate the total sum by adding up all factor buckets from vatAmountsPerFactor
    return Object.values(this.vatAmountsPerFactor)
      .reduce((acc, v) => acc.add(v), new Big(0))
      .mul(this.discountFactor);
  }

  get totalDiscount(): Big {
    return this.lines
      .reduce((s, e) => s.add(e.subTotalInclDiscount), new Big(0))
      .mul(new Big(1).sub(this.discountFactor));
  }

  get discountFactor(): Big {
    return new Big(1).sub(new Big(this.discount).div(100));
  }

  static deserialize(json: Record<string, unknown>): Invoice {
    return Deserialize(json, Invoice);
  }

  /**
   * Determines the currently in-use VAT codes
   *
   * @returns {InvoiceVAT[]} VAT codes in use, sorted by factor
   */
  usedVATCodes(): InvoiceVAT[] {
    const codes: InvoiceVAT[] = [];
    for (const line of this.lines) {
      if (
        line.vat &&
        codes.findIndex((e) => e.factor === line.vat!.factor) === -1
      ) {
        codes.push(line.vat);
      }
    }
    codes.sort((a, b) => a.factor - b.factor);
    return codes;
  }

  /**
   * Returns the amount of VAT calculated for a given VAT code over the entire invoice.
   *
   * @param {number} factor Factor of the VAT to determine the amount for
   * @returns {number} The total amount of VAT for the given factor
   */
  amountVatForFactor(factor: number): Big {
    return this.lines
      .filter((e) => e.vat && e.vat.factor === factor)
      .reduce((s, e) => s.add(e.vatAmount), new Big(0))
      .mul(this.discountFactor)
      .round(2, BIG_ROUND_HALF_EVEN);
  }

  get trackingType(): string {
    if (this.isQuotation) {
      return 'Quotation';
    }

    if (this.isRecurring) {
      return 'Recurring';
    }

    switch (this.type) {
      case InvoiceType.STANDARD:
        return 'Standard';
      case InvoiceType.CREDIT:
        return 'Credit';
      case InvoiceType.AUTOMATIC_COLLECTION:
        return 'Automatic Collection';
    }

    return 'Unknown';
  }

  public serialize(): any {
    return Serialize(this, Invoice);
  }

  public static OnDeserialized(
    instance: Invoice,
    json: Record<string, any>,
  ): void {
    instance.sender = InvoiceSender.deserialize(json.sender);
    instance.receiver = InvoiceReceiver.deserialize(json.receiver);
    instance.meta = InvoiceMeta.deserialize(json.meta);
    instance.text = InvoiceText.deserialize(json.text);
    instance.lines = json.lines.map((jsonLine) => {
      const line = InvoiceLine.deserialize(jsonLine);
      line.includesVat = instance.includesVat;
      return line;
    });
  }

  public static statusTranslationKey(status: InvoiceStatus): string {
    switch (status) {
      case InvoiceStatus.DRAFT:
        return 'invoice.types.draft';

      case InvoiceStatus.QUOTATION:
      case InvoiceStatus.DRAFT_QUOTATION:
      case InvoiceStatus.ARCHIVED_QUOTATION:
        return 'invoice.types.quotation';

      case InvoiceStatus.RECURRING:
        return 'invoice.types.recurring';

      default:
        return '';
    }
  }
}
