

























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import AutocompleteInput from '@/components/form/AutocompleteInput.vue';
import SearchResults from '@/components/SearchResults.vue';

import { SearchService } from '@/lib/services';
import { SearchResult, SearchResultMap } from '@/models';

import { debounce } from 'lodash-es';
import { sleep } from '@/lib/helpers';

@Component({
  components: {
    SearchResults,
    AutocompleteInput,
  },
})
export default class SearchBar extends Vue {
  searchString: string = '';

  searchDebounce = debounce(this.search, 200);
  results: SearchResultMap | null = null;

  async search(): Promise<void> {
    if (!this.searchString) {
      this.results = {};
    }

    this.results = this.transformResults(
      await SearchService.search(this.searchString),
    );
  }

  transformResults(results: SearchResult[]): SearchResultMap {
    const map: SearchResultMap = {};

    const addEntry = (item) => {
      const type = item['type'];

      if (!map.hasOwnProperty(type)) {
        map[type] = [];
      }

      map[type].push(item);
      // // Its impossible for the key not to exist now
      // const list = map[type]!;
      // if (list.length < MAX_RESULTS_PER_CATEGORY) {
      //   list.push(item);
      // }
    };

    for (const item of results) {
      addEntry(item);
      if (item['type'] === 'customer') {
        addEntry(
          Object.assign(new SearchResult(), item, { type: 'customer_invoice' }),
        );
      }
    }

    return map;
  }

  async clearSearch(): Promise<void> {
    await sleep(200);
    this.searchString = '';
  }

  gotoOption(selected: SearchResult): void {
    if (selected.route !== '') {
      this.$router.push(selected.route);
    }
  }
}
