// Copy this file to config.ts and change as required.
export default {
  api: {
    base: 'https://api-rewrite.beta.thenextsoftware.io/api/v1',
  },
  notifications: {
    base: 'wss://notifications.thenextsoftware.io/ws',
  },
  raven: '',
  slaask: '',
  turnstile: '0x4AAAAAAAPlkGL6GRxPkEh1',
  matomo: {
    base: '',
    site: 0,
  },
};
