import { MutationTree } from 'vuex';
import { ThemeState } from '@/store/modules/theme/state';
import { ThemeColors } from '@/lib/themes';

function set(state: ThemeState, newState: ThemeState): void {
  state.assign(newState);
}

function setColors(state: ThemeState, colorTheme: ThemeColors): void {
  Object.assign(state.colors, colorTheme);
}

function clear(state: ThemeState): void {
  state.assign(new ThemeState());
}

export default {
  set,
  setColors,
  clear,
} as MutationTree<ThemeState>;
