








































































import Vue from 'vue';
import { Component, Model, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import autosize from 'autosize';

@Component
export default class AutocompleteInput extends Vue {
  @Model('input')
  content: string;

  @Prop()
  property: string;

  @Prop() icon: string;

  @Prop({ default: '' })
  placeholder: string;

  @Prop({ required: true })
  getOptions: (s: string) => Promise<any[]>;

  @Prop({ default: () => ({}) })
  inputClass: Record<string, any>;

  @Prop({ default: false, type: Boolean })
  isTextArea: boolean;

  isOpen: boolean = false;
  highlightedPosition: number = 0;
  options: any[] = [];

  $refs: {
    input: HTMLInputElement;
  };

  searchDebounce = debounce(this.search, 200);

  mounted(): void {
    autosize(this.$refs.input);
  }

  onInput(): void {
    this.isOpen = true;
    this.highlightedPosition = 0;
    this.searchDebounce();
    this.$emit('input', this.$refs.input.value);
    if (this.isTextArea) {
      Vue.nextTick(() => {
        this.$refs.input.style.setProperty('height', 'auto');
        autosize.update(this.$refs.input);
      });
    }
  }

  async search(): Promise<void> {
    this.options = await this.getOptions(this.$refs.input.value);
  }

  onBlur(): void {
    this.$emit('blur');
    this.isOpen = false;
  }

  onFocus(): void {
    this.$emit('focus');
    this.onInput();
  }

  move(amount: number): void {
    if (!this.isOpen) {
      return;
    }

    this.highlightedPosition =
      (this.highlightedPosition + amount) % this.options.length;
    if (this.highlightedPosition < 0) {
      this.highlightedPosition = this.options.length - 1;
    }
  }

  select(event: Event): void {
    if (this.options[this.highlightedPosition]) {
      event.preventDefault();
      this.$emit('select', this.options[this.highlightedPosition]);
      this.options = [];
      if (this.isTextArea) {
        Vue.nextTick(() => autosize.update(this.$refs.input));
      }
    }
    this.isOpen = false;
  }
}
