





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';

type ToastLevel = 'info' | 'success' | 'warning' | 'error';
interface ToastAction {
  name: string;
  handler: () => void;
}

interface Toast {
  key: number;
  title: string;
  message?: string;
  level: ToastLevel;
  timeout: number;
  action?: ToastAction;
}

@Component
export default class Toaster extends Vue {
  nextKey: number = 0;
  toasts: Toast[] = [];

  levelIcon(level: ToastLevel): string {
    switch (level) {
      case 'info':
        return 'info';
      case 'success':
        return 'check';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      default:
        return 'info';
    }
  }

  info(title: string, message: string = ''): void {
    this.add(title, message, 'info');
  }

  success(title: string, message: string = ''): void {
    this.add(title, message, 'success');
  }

  warning(title: string, message: string = ''): void {
    this.add(title, message, 'warning');
  }

  error(title: string, message: string = ''): void {
    this.add(title, message, 'error');
  }

  add(
    title: string,
    message: string,
    level: ToastLevel = 'info',
    timeout: number = 5000,
    action?: ToastAction,
  ): void {
    if (!this.$parent) {
      this.$mount();
      document.body.appendChild(this.$el);
    }

    this.nextKey += 1;
    const item: Toast = {
      title,
      message,
      level,
      timeout,
      action,
      key: this.nextKey,
    };
    this.toasts.push(item);
    setTimeout(() => this.remove(item), timeout);
  }

  remove(item: Toast): void {
    const i = this.toasts.indexOf(item);
    if (i >= 0) {
      this.toasts.splice(i, 1);
    }
  }
}
