




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const sTheme = namespace('theme');

@Component
export default class Logo extends Vue {
  @sTheme.State('logo') logo!: string;

  public defaultLogo = require('@/assets/logos/thenextinvoice.png');
}
