








import { Component, Prop } from 'vue-property-decorator';

import { ActionButton } from '.';

@Component({
  components: { ActionButton },
})
export default class ActionRouterLink extends ActionButton {
  @Prop({ required: true })
  link: string;
}
