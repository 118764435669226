import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { CoronaCosts } from '@/lib/corona/costs';
import { CoronaDebt } from '@/lib/corona/debt';
import { ExploitationMatrix } from '@/lib/corona/exploitation_matrix';
import { LiquidityMatrix } from '@/lib/corona/liquidity_matrix';

export class Prognosis {
  @autoserializeAs('id') public id: number;

  @autoserializeAs('start_month') public startMonthRaw: string = '0';
  @autoserializeAs('start_year') public startYearRaw: string = '2020';
  @autoserializeAs('revenue') public revenue: number[][];

  @autoserializeAs('costs') public costs: CoronaCosts;
  @autoserializeAs('debts') public debts: CoronaDebt[] = [];

  months = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
  ];

  constructor() {
    this.revenue = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ];
    this.costs = new CoronaCosts();
  }

  debtForDate(date: Date): number {
    let totalDebt = 0;
    for (const debt of this.debts) {
      totalDebt += debt.getPaymentForMonth(date);
    }

    return totalDebt;
  }

  liquidityMatrix(yearIndex: number): LiquidityMatrix {
    return new LiquidityMatrix(this, yearIndex);
  }

  exploitationMatrix(yearIndex: number): ExploitationMatrix {
    return new ExploitationMatrix(this, yearIndex);
  }

  monthForIndex(index: number): number {
    return (index + this.startMonth) % 12;
  }

  yearForIndex(yearIndex: number, monthIndex: number): number {
    if (this.startDate == null) {
      return 0;
    }

    const startYear = this.startDate.getFullYear();
    let currentYear = startYear + yearIndex;
    // check if we're wrapping around midway
    if (monthIndex + this.startMonth > 11) {
      currentYear += 1;
    }
    return currentYear;
  }

  yearStringForIndex(index: number): string {
    if (this.startDate == null) {
      return 'N/A';
    }

    const startYear: number = this.startDate.getFullYear();
    let years: string[] = [];
    // starts on january, so years don't have to be ranges
    if (this.startDate.getMonth() === 0) {
      years = [startYear.toString(), (startYear + 1).toString()];
    } else {
      years = [
        `${startYear}-${startYear + 1}`,
        `${startYear + 1}-${startYear + 2}`,
      ];
    }

    return years[index];
  }

  get startDate(): Date | null {
    const month = Number.parseInt(this.startMonthRaw);
    const year = Number.parseInt(this.startYearRaw);

    if (isNaN(month) || isNaN(year)) {
      return null;
    }

    return new Date(year, month, 1);
  }

  get startMonth(): number {
    if (this.startDate == null) {
      return 0;
    }
    return this.startDate.getMonth();
  }

  get disclaimer(): string[] {
    return [
      'Hoewel wij de grootste zorg dragen voor de juistheid van dit rapport, is TheNextInvoice B.V. geen accountantskantoor noch een geaccrediteerde accountant.',
      'Aan dit rapport kunnen op geen enkele wijze rechten ontleend worden. Tevens omvat de inhoud van dit rapport op geen enkele wijze financieel advies.',
      'Consulteer uw accountant alvorens u dit rapport gebruikt voor financiele doeleinden. TheNextInvoice B.V. is niet aansprakelijk',
      'voor enige schade als gevolg van onjuistheden of onvolkomenheden van dit rapport.',
    ];
  }

  static deserialize(json: Record<string, unknown>): Prognosis {
    return Deserialize(json, Prognosis);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, Prognosis);
  }

  public static OnDeserialized(
    instance: Prognosis,
    json: Record<string, any>,
  ): void {
    instance.costs = Object.assign(new CoronaCosts(), json.costs);
    instance.debts = json.debts.map((e) =>
      Object.assign(
        new CoronaDebt(e.holder, e.amount, e.repaymentTerm, e.repaymentStart),
        e,
      ),
    );
  }
}
