import { ActionTree, ActionContext } from 'vuex';
import { ThemeState } from '@/store/modules/theme/state';
import { ThemeColors } from '@/lib/themes';

function set(
  store: ActionContext<ThemeState, any>,
  newState: ThemeState,
): void {
  store.commit('set', newState);
}

function setColorScheme(
  store: ActionContext<ThemeState, any>,
  colors: ThemeColors,
): void {
  store.commit('setColors', colors);
}

function clear(store: ActionContext<ThemeState, any>): void {
  store.commit('clear');
}

export default {
  set,
  setColorScheme,
  clear,
} as ActionTree<ThemeState, any>;
